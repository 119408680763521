import { useEffect, useRef } from "react"

import Popup from "@/components/shared/Popup/Popup"
import AppState, { POPUPS } from "@/js/AppState"
import PopupsManager from "react-sdk/PopupsManager"
import classes from "./PopupChallengePhoto.module.css"

import { observer } from "mobx-react-lite"
import Body from "./Body/Body"
import ChallengePhoto, { CLOSED, OPENED } from "./ChallengePhoto"
import Footer from "./Footer/Footer"
import { FaCheck } from "react-icons/fa6"
import Texts from "react-sdk/Texts"

const PopupChallengePhoto = () => {
  const thumbnailsCtnrRef = useRef(null)
  useEffect(() => {
    let { activityNetId } = PopupsManager.getData(POPUPS.CHALLENGE_PHOTO)
    ChallengePhoto.init(AppState.vertxClient.UserData.getUserID(), AppState.team.groupId, activityNetId)

    return () => {
      ChallengePhoto.cleanup()
    }
  }, [])

  if (!ChallengePhoto.activity) return null

  let scheduledCloseDate = new Date(ChallengePhoto.activity.ScheduledClosingTime)
  let closeDate = new Date(ChallengePhoto.activity.CloseTime)

  return (
    <Popup className={classes.PopupChallengePhoto} onClose={() => PopupsManager.close(POPUPS.CHALLENGE_PHOTO)}>
      <div className={classes.header}>
        {ChallengePhoto.state === OPENED && (
          <div
            className={classes.date_fin}
            dangerouslySetInnerHTML={{
              __html: Texts.get("challengephoto-header-scheduled-end-date", {
                mustacheVars: {
                  date: scheduledCloseDate.toLocaleDateString(),
                  time: scheduledCloseDate.toLocaleTimeString()
                }
              })
            }}
          />
        )}

        {ChallengePhoto.state === CLOSED && (
          <div
            className={classes.date_fin}
            dangerouslySetInnerHTML={{
              __html: Texts.get("challengephoto-header-real-end-date", {
                mustacheVars: {
                  date: closeDate.toLocaleDateString(),
                  time: closeDate.toLocaleTimeString()
                }
              })
            }}
          />
        )}

        <div className={classes.consigne}>{ChallengePhoto.consigne || "Consigne manquante !"}</div>
      </div>

      <Body />
      <Footer />
    </Popup>
  )
}

export default observer(PopupChallengePhoto)
