import React, { useState, useEffect } from "react"
import Loader from "react-sdk/components/Loader/Loader"
import classes from "./Body.module.css"
import ChallengePhoto, { CLOSED, OPENED } from "../ChallengePhoto"
import AppState from "@/js/AppState"
import { FaCamera, FaSpinner } from "react-icons/fa6"
import ImageUploadButton from "./ImageUploadButton"
import { observer } from "mobx-react-lite"
import Notation, { Stars } from "../Notation/Notation"
import Texts from "react-sdk/Texts"
import Button from "@/components/shared/Button/Button"
import { toJS } from "mobx"

const NoPhoto = () => {
  if (AppState.userIsReferent) {
    return (
      <>
        <div className={classes.camera_icon}>
          <FaCamera />
          <span>+</span>
        </div>

        <div className={classes.notif}>
          <ImageUploadButton
            buttonComponent={<div className={classes.ImageUploadBtnText}>{Texts.get("challengephoto-add-photo")}</div>}
            loadingComponent={<div>{Texts.get("challengephoto-loading")}</div>}
          />
        </div>
      </>
    )
  }

  return <div className={classes.notif}>{Texts.get("challengephoto-add-photo-no-referent")}</div>
}

const ReasonsCount = ({ reasonsCount }) => {
  let keys = Object.keys(reasonsCount)

  let total = Object.values(reasonsCount).reduce((total, val) => total + val, 0)

  return (
    <div className={classes.ReasonsCount}>
      {keys.length === 1 && <div style={{ color: "#eb4338" }}>{Texts.get(keys[0])}</div>}

      {keys.length > 1 && (
        <>
          <div style={{ color: "#eb4338" }}>{Texts.get("challengephoto-photo-disapproved")}</div>
          <div className={classes.ReasonsCount_reasons}>
            {keys.map(k => {
              let val = reasonsCount[k]
              let percent = (val / total) * 100
              return (
                <div className={classes.reasonChart}>
                  <div className={classes.reasonBar} style={{ width: percent + "%" }}></div>
                  {Texts.get(k)}
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

const Body = () => {
  let myEvaluation
  // on récupère notre évaluation sur les photos qui ne sont pas la notre
  if (ChallengePhoto.selectedThumbId && ChallengePhoto.selectedThumb.TeamID !== ChallengePhoto.teamId) {
    myEvaluation = ChallengePhoto.getMyEvaluation(ChallengePhoto.selectedThumb)
  }

  console.log("ChallengePhoto.myData", toJS(ChallengePhoto.myData))

  const won =
    ChallengePhoto.state === CLOSED &&
    ChallengePhoto?.myData?.Validated === true &&
    ChallengePhoto?.myData?.TeamProgressionCompleted

  const lost =
    ChallengePhoto.state === CLOSED &&
    (!ChallengePhoto?.myData?.Validated || !ChallengePhoto?.myData?.TeamProgressionCompleted)

  return (
    <div className={classes.Body}>
      {ChallengePhoto.loading && (
        <div className={classes.loading}>
          <Loader />
        </div>
      )}

      {!ChallengePhoto.loading && !ChallengePhoto.myData && (
        <>
          {ChallengePhoto.state === OPENED && <NoPhoto />}
          {ChallengePhoto.state === CLOSED && (
            <div className={classes.notif}>
              <p style={{ color: "#eb4338" }}>{Texts.get("challengephoto-no-participation")}</p>
            </div>
          )}
        </>
      )}

      {ChallengePhoto.selectedThumb && <img src={ChallengePhoto.selectedThumb.Photo} />}

      {/* On a selectionné notre photo */}
      {ChallengePhoto.selectedThumbId !== null && ChallengePhoto.selectedThumbId === ChallengePhoto.myData?._id && (
        <div className={classes.notif}>
          {won && (
            <>
              <h1>{Texts.get("challengephoto-won-title")}</h1>
              <Stars value={ChallengePhoto.myData.StarsAverage} />
            </>
          )}

          {lost && <h1>{Texts.get("challengephoto-lost-title")}</h1>}

          {/*********** GESTION DE L'EVALUATION DE NOTRE PHOTO ****************/}
          <div className={classes.cp_details}>
            {ChallengePhoto.state === OPENED && (
              <>
                {ChallengePhoto.myData.Validated === undefined && (
                  <p>
                    <FaSpinner className={classes.spin} /> {Texts.get("challengephoto-my-photo-evaluating")}
                  </p>
                )}

                {ChallengePhoto.myData.Validated === true && (
                  <p style={{ color: "#00da71" }}>{Texts.get("challengephoto-photo-approved")}</p>
                )}

                {ChallengePhoto.myData.Validated === false && (
                  <>
                    <ReasonsCount reasonsCount={ChallengePhoto.myData.ReasonsCount} />

                    {/* {AppState.userIsReferent && } */}
                    <p>
                      {AppState.userIsReferent && ChallengePhoto.activity.Opened && (
                        <ImageUploadButton
                          loadingComponent={<Button disabled>{Texts.get("challengephoto-loading")}</Button>}
                          buttonComponent={
                            <div className={classes.fake_upload_btn}>
                              <span>{Texts.get("challengephoto-photo-upload-new-photo")}</span>
                            </div>
                          }
                        />
                      )}
                    </p>
                  </>
                )}
              </>
            )}
            {ChallengePhoto.state === CLOSED && (
              <>
                {ChallengePhoto.myData.Validated === undefined && (
                  <p style={{ color: "#eb4338" }}>{Texts.get("NotEvaluatedInTime")}</p>
                )}

                {ChallengePhoto.myData.Validated === true && (
                  <p style={{ color: "#00da71" }}>{Texts.get("challengephoto-photo-approved")}</p>
                )}

                {ChallengePhoto.myData.Validated === false && (
                  <ReasonsCount reasonsCount={ChallengePhoto.myData.ReasonsCount} />
                )}
              </>
            )}

            {ChallengePhoto.state === OPENED && ChallengePhoto.selectedThumb.TeamProgressionCompleted === undefined && (
              <p style={{ color: "yellow" }}>
                {Texts.get("challengephoto-evaluate-other")} ({ChallengePhoto.selectedThumb.TeamProgression || 0}/
                {ChallengePhoto.activity.InitData.Progression})
              </p>
            )}

            {ChallengePhoto.state === CLOSED && ChallengePhoto.selectedThumb.TeamProgressionCompleted === undefined && (
              <p style={{ color: "#eb4338" }}>{Texts.get("challengephoto-progression-failed")}</p>
            )}

            {ChallengePhoto.selectedThumb.TeamProgressionCompleted && (
              <p style={{ color: "#00da71" }}>{Texts.get("challengephoto-progression-success")}</p>
            )}
          </div>

          {won && <div className={classes.jetons}>+ {ChallengePhoto.activity.InitData.Reward}</div>}
        </div>
      )}

      {/* On a selectionné une autre photo */}
      {ChallengePhoto.selectedThumbId !== null && ChallengePhoto.selectedThumbId !== ChallengePhoto.myData?._id && (
        <div className={classes.notif} key={ChallengePhoto.selectedThumbId}>
          {!myEvaluation && <Notation />}
          {myEvaluation && (
            <div>
              {myEvaluation.Evaluation === true && (
                <>
                  <p>{Texts.get("challengephoto-answer-yes")}</p>
                  <Stars value={myEvaluation.Options.Stars} />
                </>
              )}

              {myEvaluation.Evaluation === false && (
                <p>
                  {Texts.get("challengephoto-answer-no")} "{Texts.get(myEvaluation.Options.Reason)}"
                </p>
              )}

              {myEvaluation.Progression === undefined && <p>{Texts.get("challengephoto-other-photo-evaluating")}</p>}

              {myEvaluation.Progression < 0 && (
                <>
                  <p style={{ color: "#eb4338" }}>{Texts.get("challengephoto-choice-differs")}</p>
                  <p style={{ color: "#eb4338" }}>
                    {Texts.get("challengephoto-progression", {
                      mustacheVars: { progression: myEvaluation.Progression }
                    })}
                  </p>
                </>
              )}
              {myEvaluation.Progression > 0 && (
                <>
                  <p style={{ color: "#00da71" }}>{Texts.get("challengephoto-choice-similar")}</p>
                  <p style={{ color: "#00da71" }}>
                    {Texts.get("challengephoto-progression", {
                      mustacheVars: { progression: myEvaluation.Progression }
                    })}
                  </p>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default observer(Body)
