import React, { useState } from "react"
import imageCompression from "browser-image-compression"
import ChallengePhoto from "../ChallengePhoto"

const ImageUploadButton = ({ children, buttonComponent, loadingComponent }) => {
  let max_width = 1024
  let max_height = 2048
  let max_width_or_height = Math.max(max_width, max_height)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  function handleFileSelected(e) {
    // console.log("e", e.target.files)
    if (e.target.files) {
      setLoading(true)

      let file = e.target.files[0]
      console.log("file", file)

      const options = {
        maxSizeMB: 0.3,
        maxWidthOrHeight: max_width_or_height,
        useWebWorker: true,
        fileType: "image/webp"
      }

      imageCompression(file, options)
        .then(compressedFile => {
          const reader = new FileReader()
          reader.onloadend = () => {
            const base64data = reader.result
            // Création de la miniature
            const optionsMini = {
              maxSizeMB: 0.02,
              maxWidthOrHeight: max_width_or_height / 4,
              useWebWorker: true,
              fileType: "image/webp"
            }
            // également en WEBP
            imageCompression(file, optionsMini)
              .then(compressedFileMini => {
                const readerMini = new FileReader()
                readerMini.onloadend = () => {
                  const base64dataMini = readerMini.result

                  ChallengePhoto.uploadPhoto(base64data, base64dataMini)
                    .then(() => {
                      setLoading(false)
                    })
                    .catch(err => {
                      setError(err)
                    })
                }

                readerMini.readAsDataURL(compressedFileMini) // ?
              })
              .catch(error => {
                console.error(error)
              })
          }
          reader.readAsDataURL(compressedFile)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  return (
    <label>
      {!loading && buttonComponent}
      {loading && loadingComponent}
      {error && <div style={{ color: red }}>{error}</div>}
      <input type="file" style={{ display: "none" }} accept="image/png, image/jpeg" onChange={handleFileSelected} />
    </label>
  )
}

export default ImageUploadButton
