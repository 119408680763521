import AppState, { POPUPS } from "@/js/AppState"
import { observer } from "mobx-react-lite"
import PopupsManager from "react-sdk/PopupsManager"

import PopupReplayMinigame from "@/components/popups/PopupReplayMinigame/PopupReplayMinigame"
import Button from "@/components/shared/Button/Button"
import TeamHeader from "@/components/shared/TeamHeader/TeamHeader"
import Dial from "@cmp/Dial/Dial"
import ObjectiveBar from "@cmp/Hud/ObjectiveBar/ObjectiveBar"
import Map from "@cmp/Map/Map"
import PopupBoutique from "@pops/PopupBoutique/PopupBoutique"
import PopupClassement from "@pops/PopupClassement/PopupClassement"
import PopupCollecte from "@pops/PopupCollecte/PopupCollecte"
import PopupObjective from "@pops/PopupObjective/PopupObjective"
import PopupTeam from "@pops/PopupTeam/PopupTeam"
import PopupUser from "@pops/PopupUser/PopupUser"
import { FaCameraRetro, FaRegBell } from "react-icons/fa6"
import classes from "./ScreenGame.module.css"

import ChapterBar from "@/components/Hud/ChapterBar/ChapterBar"
import PopupMinigameSequence from "@/components/popups/PopupMinigameSequence/PopupMinigameSequence"
import PopupMinigameStars from "@/components/popups/PopupMinigameStars/PopupMinigameStars"
import PopupNouveautes from "@/components/popups/PopupNouveautes/PopupNouveautes"
import GroupMessages from "@/js/GroupMessages"
import Minigames from "@/js/Minigames/Minigames"
import SaveGame from "@/js/SaveGame"
import clsx from "clsx"
import { useEffect } from "react"
import { getUrlVar } from "react-sdk/utils"

import PopupChallengePhoto from "@/components/popups/PopupChallengePhoto/PopupChallengePhoto"
import PopupChallengePhotoList from "@/components/popups/PopupChallengePhotoList/PopupChallengePhotoList"
import DebugHud from "./DebugHud/DebugHud"

const ScreenGame = () => {
  useEffect(() => {
    if (AppState.test_mg_mode) return

    if (!SaveGame.getState("sequenceIntroVue")) {
      SaveGame.setState("sequenceIntroVue", true)
      SaveGame.save()
    }
  }, [])

  function handleTeamPPClick() {
    console.log("LOCKHUD : test", AppState.lockHud)

    if (AppState.lockHud) return

    PopupsManager.open(POPUPS.TEAM)
  }

  return (
    <div className={classes.ScreenGame}>
      {getUrlVar("nomap") === null && <Map visible={AppState.mapVisible} />}

      <div
        className={clsx(
          classes.top_hud,
          !AppState.UI_visibilities.game_header && classes.invisible,
          AppState.lockHud && classes.locked
        )}
      >
        <TeamHeader small onClick={handleTeamPPClick} />

        <div className={classes.top_hud_buttons}>
          {/* <div className={classes.hud_menu_btn}><Button icon={<FaBars />} /></div> */}
          {/* <div className={classes.hud_mail_btn}><Button icon={<FaCameraRetro />} /></div> */}

          <div className={classes.hud_bell_btn} onClick={() => PopupsManager.open(POPUPS.NOUVEAUTES)}>
            <Button icon={<FaRegBell />} />
            {GroupMessages.unreadMessages.length > 0 && <div className={classes.hud_notif}></div>}
          </div>
        </div>
      </div>

      {AppState.debugMode && <DebugHud />}

      <div className={classes.bottom_hud}>
        {!PopupsManager.isOpened(POPUPS.NEW_OBJECTIVE) && <ObjectiveBar />}

        {AppState.soloObjectives.rootObjectives.length > 0 && <ChapterBar />}
      </div>

      {PopupsManager.isOpened(POPUPS.TEAM) && <PopupTeam team={AppState.team} />}

      {PopupsManager.isOpened(POPUPS.USER) && <PopupUser />}

      {PopupsManager.isOpened(POPUPS.NOUVEAUTES) && <PopupNouveautes />}

      {PopupsManager.isOpened(POPUPS.COLLECTE) && <PopupCollecte />}

      {PopupsManager.isOpened(POPUPS.LEADERBOARD) && <PopupClassement />}

      {PopupsManager.isOpened(POPUPS.BOUTIQUE) && <PopupBoutique />}

      {PopupsManager.isOpened(POPUPS.NEW_OBJECTIVE) && <PopupObjective />}

      {PopupsManager.isOpened(POPUPS.REPLAY_MG) && <PopupReplayMinigame />}

      {Minigames.current && <PopupMinigameSequence />}

      {PopupsManager.isOpened(POPUPS.CHALLENGE_PHOTO_LIST) && <PopupChallengePhotoList />}

      {PopupsManager.isOpened(POPUPS.CHALLENGE_PHOTO) && <PopupChallengePhoto />}

      {PopupsManager.isOpened(POPUPS.MINIGAME_STARS) && <PopupMinigameStars />}

      <Dial />
    </div>
  )
}

export default observer(ScreenGame)
