import React from 'react';

import classes from './Footer.module.css';
import PopupsManager from 'react-sdk/PopupsManager';
import { POPUPS } from '@/js/AppState';
import Texts from 'react-sdk/Texts';


const Footer = () => {

  function handleClick(e) {
    e.preventDefault()

    /** @type {import('@/components/popups/PopupNotif/PopupNotif').NotifPopupOptions} */
    let data = {
      text: Texts.get("mentions-legales-text"),
      textAlign: "left",
      onClose: () => PopupsManager.close(POPUPS.NOTIF)
    }


    PopupsManager.open(POPUPS.NOTIF, data)
  }

  return (
    <div className={classes.Footer}>
      <div className={classes.succubus}>
        Seriously made with fun by Ludicius Interactive
      </div>
      <div>
        <a href="#" onClick={handleClick}>{Texts.get("mentions-legales-link")}</a>
      </div>
      <div className={classes.version}>v{import.meta.env.VITE_APP_VERSION}</div>
    </div>
  );
};

export default Footer;
