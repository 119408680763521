import {
  PopupMGReglesBtn,
  PopupNotifStartMinigameBtns,
} from "@/components/popups/PopupNotif/PopupNotifBtns"
import { makeAutoObservable } from "mobx"
import { DragDropFile } from "react-sdk/activities/DragDrop"
import { QCMFile } from "react-sdk/activities/QCM"
import { PointerValiderFile } from "react-sdk/activities/PointerValider"
import PopupsManager from "react-sdk/PopupsManager"
import Analytics from "react-sdk/Vertx/Analytics"
import AppState, { POPUPS } from "../AppState"
import Minigame, { MinigameLevel } from "./Minigame"
import MinigameFtues from "./MinigameFtues"

export const MG_TYPES = {
  QUIZ: "quiz",
  DRAGDROP: "dragdrop",
  MATCH3: "match3",
  WIRELABY: "wireLaby",
  CONNECTIONLINK: "connectionLink",
  BRICKBREAKER: "brickBreaker",
  WORDFIND: "wordFind",
  COLOURPUZZLE: "colourPuzzle",
  PARKING: "parking",
  POINTERVALIDER: "pointerValider"
}

class Minigames {
  /** @type {QCMFile} */
  qcms = null

  /** @type {DragDropFile} */
  dragdrops = null

  /** @type {PointerValiderFile} */
  pointerValider = null


  /** @type {Array<Minigame>} */
  all = []

  /** @type {Array<MinigameLevel>} */
  levels = []

  currentLevelIndex = null
  /** @type {Minigame} */
  current = null

  isReplay = false
  onEnd = null
  constructor() {
    makeAutoObservable(this)
  }

  init(minigames, minigames_levels, qcms, dragdrops, pointerValider) {
    this.qcms = new QCMFile(qcms)
    this.qcms.observeAll()

    this.dragdrops = new DragDropFile(dragdrops)
    this.dragdrops.observeAll()


    this.pointerValider = new PointerValiderFile(pointerValider)
    this.pointerValider.observeAll()

    this.levels = minigames_levels
      .filter(elem => elem.id)
      .map(elem => new MinigameLevel(elem))

    this.all = minigames.filter(elem => elem.id).map(elem => new Minigame(elem))

  }

  get ids() {
    return this.all.map(c => c.id)
  }

  get(id) {
    return this.all.find(c => c.id === id)
  }
  getLevel(id) {
    let lev = this.levels.find(l => l.id === id)

    return lev
  }

  get unlocked() {
    return this.all.filter(mg => mg.unlocked)
  }

  play(id, onEnd, isReplay = false) {
    this.onEnd = onEnd
    this.isReplay = isReplay

    let mg = this.get(id)

    if(!mg) {
      throw new Error(`Minigame ${id} does not exist`)
    }

    mg.unlock()

    /** @type {import("@/components/popups/PopupNotif/PopupNotif").NotifPopupOptions} */
    let datas = {
      title: mg.title,
      text: mg.description,
      image_url: mg.img_url,
      Buttons: PopupNotifStartMinigameBtns.bind(null, { mg }), // <-instance d'un component react avec des props
      onClose: () => {
        PopupsManager.close(POPUPS.NOTIF)
        AppState.reaxe_api.toHaxe("map", "NotifyPoi", "UIpoi5_mg", () => { }) // Hightlight Mg poi
        
        this.stop(null)
      },
    }

    PopupsManager.open(POPUPS.NOTIF, datas)
  }

  launch(minigame, level_index) {

    console.log(minigame)

    if (MinigameFtues.hasBeenViewed(minigame.type)) {

      console.log("MinigameFtues.hasBeenViewed")

      this.reallyLaunch(minigame, level_index)
      return
    }

    const pages = MinigameFtues.getForMG(minigame.type)
    if (pages.length === 0) {
      console.log(" MinigameFtues.getForMG(minigame.type) empty")
      
      this.reallyLaunch(minigame, level_index)
      return
    }


    console.log("FTUE")

    // HACK on ajoute le bouton sur la dernière page
    pages.at(-1).Buttons = PopupMGReglesBtn



    /** @type {import("@/components/popups/PopupNotif/PopupNotif").NotifPopupOptions} */
    const data = { Pages: pages }

    console.log(data)


    PopupsManager.open(POPUPS.NOTIF, data, () => {
      // HACK on delete le bouton sur la dernière page
      delete pages.at(-1).Buttons
      this.reallyLaunch(minigame, level_index)
    })
  }

  reallyLaunch(minigame, level_index) {
    this.currentLevelIndex = level_index
    this.current = minigame
    Analytics.changeScreenEvents(null, ("popup.MINIGAME." + (this.isReplay ? "REPLAY." : "") + this.current.id))
  }

  stop(score) {
    if (this.currentLevelIndex !== null) {
      this.current.levels[this.currentLevelIndex].playCount++
      // ici calculer le nobre d'étoiles gagnées, et le stocker

      let stars = this.current.calculateStars(score, this.currentLevelIndex)
      if (stars) {
        AppState.userSaveStars(stars)
      }
    }

    const cb = () => {
      if(this.current) {
        Analytics.changeScreenEvents(("popup.MINIGAME." + (this.isReplay ? "REPLAY." : "") + this.current.id))
      }

      this.current = null
      this.currentLevelIndex = null

      if (this.onEnd) this.onEnd() // <- continue la séquence de scénario
      this.onEnd = null
      this.isReplay = false
    }
    // ICI ouvrir la popup de stars
    if (score !== null) {
      let data = { score, level: this.current.levels[this.currentLevelIndex] }
      PopupsManager.open(POPUPS.MINIGAME_STARS, data, cb)
    } else {
      cb()
    }
  }

  stateToJson() {
    return this.all.map(mg => {
      return {
        id: mg.id,
        unlocked: mg.unlocked,
        levels: mg.levels.map(l => ({
          id: l.id,
          stars: l.stars,
          unlocked: l.unlocked,
          playCount: l.playCount,
        })),
      }
    })
  }
  stateFromJson(data) {
    data.forEach(d => {
      let mg = this.get(d.id)
      if (mg) {
        mg.unlocked = d.unlocked

        d.levels.forEach(level_data => {
          let l = mg.getLevel(level_data.id)
          if (l) {
            l.stars = level_data.stars
            l.unlocked = level_data.unlocked
            l.playCount = level_data.playCount
          }
        })
      }
    })
  }
}

export default new Minigames()
