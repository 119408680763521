// le but de cette classe est de formater un fichier .data en json spécifique QCM pour le mettre dans une activity
// il permet aussi de relire ce json et de le manipuler côté react

import { action, computed, makeObservable, observable } from "mobx"
import { pickRandom } from "../utils"
import { STATES } from "./ActivityPedaInstance"
import Debrief from './Debrief'

import Notifications from "../Notifications/Notifications"
import ActivityPedaInstance from "./ActivityPedaInstance"
import ActivityResult from "./ActivityResult"


class Choice {

  selected = false
  locked = false

  isCorrect

  constructor(json, observe) {
    Object.assign(this, json)
    if(observe) this.observe()
  }

  observe() {
    makeObservable(this, {
      selected: observable,
      locked: observable,
      state: computed,
      setSelected: action
    })
  }

  setSelected(val) {
    this.selected = val
  }

  get state() {
    if(this.isCorrect && this.selected) return STATES.CORRECT
    if(!this.isCorrect && this.selected) return STATES.WRONG
    if(this.isCorrect && !this.selected) return STATES.MISSED
    return null

  }

}


// représente une seule question, avec ses choices et debriefs
export class QCM extends ActivityPedaInstance {

  ID
  Type
  Text = null
  media = null

  /** @type {Array<Choice>} */
  choices = []

  Duration = null

  QrlAnswer = ""

  constructor(row) {
    super()
    Object.assign(this, row)
    // NOTE : parfois on ne le veut pas observable, quand on l'utilise depuis la classe QCMFile
  }

  fromJson(json) {
    Object.assign(this, json)
    this.choices = this.choices.map(c => new Choice(c, true))
    this.observe()
    return this
  }

  addChoice(json) {
    this.choices.push(new Choice(json))
  }


  handleJauges(jauges) {
    // on regarde les réponses qu'on a cochées
    this.choices.filter(c => c.selected).forEach(c => {
      // si elles ont une info de jauge

      if(c.jauge) {
        try {
          let jauge_obj = JSON.parse(c.jauge)
          Object.keys(jauge_obj).forEach(jauge_id => {
            let success = jauges.increment(jauge_id, jauge_obj[jauge_id])
            if(!success) Notifications.error("Erreur colonne 'jauge' pour le QCM " + this.ID + " : cette id de jauge n'existe pas dans 'jauge.data' : " + jauge_id)
          })
        }
        catch(err) {
          Notifications.error("Erreur colonne 'jauge' pour le QCM " + this.ID)
        }
      }

    })

  }
  localCorrect() {
    // ici on va créer nous même le "result" au lieu de l'attendre du serveur

    // c'est correct si pas de wrong ni de missed

    if(this.Type === "question" || this.Type === "qcm") {

      let nb_user_correct = this.choices.filter(c => c.state === STATES.CORRECT).length
      let nb_correct = this.choices.filter(c => c.isCorrect).length


      // let nb_wrong = this.choices.filter(c => c.state === STATES.WRONG).length
      // let nb_missed = this.choices.filter(c => c.state === STATES.MISSED).length

      let result = new ActivityResult()
      result.normalized_score =  nb_user_correct / nb_correct;
      result.is_correct = result.normalized_score === 1 //nb_missed === 0 && nb_wrong === 0,

      this.setResult(result)
    }

    if(this.Type === "qrl") {
      let qrlAnswer = this.QrlAnswer

      if(!qrlAnswer) {
        let result = new ActivityResult()
        result.is_correct = false
        result.normalized_score = 0
        this.setResult(result)
        return
      }


      let answer = qrlAnswer.trim().toLowerCase()
      let correctAnswer = this.choices.find(c => {
        let choice_text = c.Text.trim().toLowerCase()
        return choice_text === answer

        // return c.Text.localeCompare(qrlAnswer.trim(), undefined, { sensitivity: 'base' }) === 0
      })

      let result = new ActivityResult()
      result.is_correct = correctAnswer !== undefined
      result.normalized_score = result.is_correct ? 1 : 0
      if(correctAnswer) result.additionalInfos = { correctWord: correctAnswer.Text } // on renvoie le mot qui a matché, c'est pas forcément le premier
      this.setResult(result)
    }
  }

  get MultipleChoiceMode() {
    return this.choices.filter(c => c.isCorrect).length > 1
  }

  toggleChoice(choice) {

    if (choice.locked) return

    if(this.MultipleChoiceMode) {
      choice.setSelected(!choice.selected)
    }
    else {
      this.choices.forEach(c => c.setSelected(false))
      choice.setSelected(true)
    }
  }

  getAnswer() {
    return this.selectedChoicesIds
  }

  get selectedChoices() {
    return this.choices.filter(c => c.selected)
  }

  get unselectedChoices() {
    return this.choices.filter(c => !c.selected)

  }

  get selectedChoicesIds() {
    return this.selectedChoices.map(c => c.ID)
  }

  useJoker() {
    if(!this.jokerAvailable) return

    if(this.MultipleChoiceMode) {
      // on préselectionne un choice correct


      let c = pickRandom(this.choices.filter(c => c.isCorrect))
      c.selected = true
      c.locked = true
    }
    else {
      // on supprime un choice incorrect
      let c = pickRandom(this.choices.filter(c => !c.isCorrect))
      c.selected = false
      c.locked = true
    }

    this.jokerUsed = true
  }

  get jokerAvailable() {
    return this.choices.length > 2
  }

  reset() {
    this.result = null
    this.QrlAnswer = ""
    this.choices.forEach(c => {
      c.selected = false
    })
  }

}


// représente tous les QCM
export class QCMFile {
  /** @type {Array<QCM>} */
  all = []

  constructor(json, file) {
    let current = null

    json.forEach(row => {
      try {
        if(row.Type === "question" ||row.Type === "qrl" || row.Type === "swipe") {
          current = new QCM(row)
          this.all.push(current)
        }

        if(row.Type === "choice") {
          current.addChoice(row)
        }

        if(row.Type === "debrief") {
          current.addDebrief(row, row.isCorrect)
        }

        if(row.Type === "debrief_correct") {
          current.addDebrief(row, true)
        }
        if(row.Type === "debrief_incorrect") {
          current.addDebrief(row, false)
        }
      }
      catch(err) {
        throw "ERREUR CREATION QCM : " + row.ID + " dans " + file
      }
    })

  }
  get(id) {
    return this.all.find(qcm => qcm.ID === id)
  }

  observeAll() {
    this.all.forEach(qcm=> {
      qcm.observe()
      makeObservable(qcm, { QrlAnswer: observable })
      qcm.choices.forEach(c=>c.observe())
    })
  }
}