import { makeAutoObservable } from "mobx"
import AppState, { POPUPS } from "./AppState"
import Notifications from "react-sdk/Notifications/Notifications"
import PopupsManager from "react-sdk/PopupsManager"
import Texts from "react-sdk/Texts"
import { PopupNotifImReferentBtn } from "@/components/popups/PopupNotif/PopupNotifBtns"

export class Team {
  groupId



  id_magasin
  enseigne
  region
  nom_pdc
  gestionnaire
  telephone
  ville
  code_postal
  adresse
  adresse2


  teamPseudo

  coins = 0

  users = []

  referentsIds = []

  progress = {} // permet d'enregistrer les progress des autres joueurs

  constructor(groupId) {

    this.groupId = groupId

    makeAutoObservable(this)
  }

  get groupReferentFullName() {
    return this.groupId.replace("magasins.", "referents.")
  }


  fetchInfos() {
    // ici récupérer les infos de l'équipe et du magasin

    let infosProm = new Promise( (resolve, reject) => {
      AppState.vertxClient.GroupCustomDataGetItem(this.groupId, "Infos", (success, msg, data) => {
        if(success) {
          Object.assign(this, data)
          resolve(data)
        }
        else {
          reject("Team fetch Infos " + msg)
        }
      })
    })


    let coinsProm = new Promise( (resolve, reject) => {
      AppState.vertxClient.GroupCustomDataGetItem(this.groupId, "Points", (success, msg, data) => {
        if(success) {

          if(typeof data === "object") {
            this.coins = 0
          }
          else if(data === null) {
            this.coins = 0
          }
          else {
            this.coins = data
          }
          resolve(data)
        }
        else {
          reject("Team fetch Points " + msg)
        }
      })
    })

    let progressProm = new Promise( (resolve, reject) => {
      AppState.vertxClient.GroupCustomDataGetItem(this.groupId, "Progress", (success, msg, data) => {

        if(success) {
          if(data) {
            this.progress = data
          }

          resolve(data)
        }
        else {
          reject("Team fetch progress " + msg)
        }
      })
    })

    return Promise.all([infosProm, coinsProm, progressProm])
  }

  fetchUsers() {
    let user = AppState.vertxClient.UserData
    let universe = user.getUniverse()


    return new Promise( (resolve, reject) => {
      AppState.vertxClient.GroupAllUsersInfo(universe, this.groupId, (success, msg, data) => {
        if(success) {
          this.users = data.filter(u => !!u) // des users peuvent être null ...
          resolve(data)
        }
        else {
          console.log("ERROR fetch users", msg)
          reject(msg)
        }
      })

    })
  }


  fetchReferents() {
    return new Promise( (resolve, reject) => {
      AppState.vertxClient.GroupAllUsersInfo(AppState.vertxClient.UserData.getUniverse(), this.groupReferentFullName, (success, msg, data) => {
        if(success) {
          data = data
          .filter(u => !!u)
          .map(u => u.UserID)

          this.referentsIds = data

          resolve(data)
        }
        else {
          console.log("ERROR fetch referents", msg)
          reject(msg)
        }
      })
    })
  }

  fetchUsersAndReferents() {
    return Promise.all([this.fetchUsers(), this.fetchReferents()])
    .then(data => console.log("fetchUsersAndReferents", data))
  }

  fetchBlasons() {
    return new Promise( (resolve, reject) => {
      AppState.vertxClient.GroupCustomDataGetItem(this.groupId, "Blasons", (success, msg, data) => {
        if(success) {
          resolve(data)
        }
        else {
          reject("Team fetch Points " + msg)
        }
      })
    })
  }

  updatePseudo(newValue) {
    return new Promise( (resolve, reject) => {
      const check_not_exist = false // on veut override
      const broadcast = true
      AppState.vertxClient.GroupCustomDataSetItem(this.groupId,"Infos.teamPseudo", newValue, check_not_exist, broadcast, (success, msg, data) => {

        if(success) {
          this.teamPseudo = newValue // on passe par les messages de groupe pour ça
          resolve()
        }
        else {
          console.log("Error update team pseudo", msg)
          reject(msg)
        }
      })
    })
  }

  removeUser(user) {
    return new Promise( (resolve, reject) => {
      let universe = AppState.vertxClient.UserData.getUniverse()

      AppState.vertxClient.GroupUserRemove(universe, this.groupId, user.UserID,(success, msg, data) => {
        if(success) {

          AppState.vertxClient.UserDelete(universe,user.UserID,null,(success, msg, data) => {
            if(success) {
              this.fetchUsers().then(resolve)
            } else {
              reject(msg)
              console.log("AdminDeleteUser error", msg)
            }
          })

        } else {
          reject(msg)
          console.log("GroupUserRemove error", msg)
        }

     })
    })
    .catch(err => Notifications.error("Une erreur est survenue pendant la suppression de l'utilisateur"))
  }

  changeReferent(new_referent) {
    const universe = AppState.vertxClient.UserData.getUniverse()
    const my_id = AppState.vertxClient.UserData.getUserID()
    const new_referent_id = new_referent.UserID




    return new Promise( (resolve, reject) => {
      // 1- on ajoute au groupe referents.[...]
      AppState.vertxClient.GroupUserAdd(universe, AppState.groupReferent, new_referent_id,(success, msg, data) => {
        console.log("GroupUserAdd", success, msg, data)
        if(success) {
          // 2- on se supprime soi-même du groupe referents.[...]
          AppState.vertxClient.GroupUserRemove(universe, AppState.groupReferent, my_id, (success, msg, data) => {
            console.log("GroupUserRemove", success, msg, data)
            if(success) {
              resolve()
            }
            else {
              // annuler ce qui a pu être fait avant ?
              console.log("ERROR GroupUserRemove", msg)
              reject()
            }
          })
        }
        else {
          console.log("ERROR GroupUserAdd", msg)
          reject()
        }
      })

    })
    .then(() => {
      /** @type {import("@/components/popups/PopupNotif/PopupNotif").NotifPopupOptions} */
      const data = {
        surtitle: Texts.get("popup-notif-attribution-referent-surtitle"),
        title:Texts.get("popup-notif-attribution-referent-title"),
        text: Texts.get("popup-notif-attribution-referent-text", {mustacheVars: {pseudo: new_referent.Pseudo}}),
        Buttons: PopupNotifImReferentBtn
      }
      PopupsManager.queue(POPUPS.NOTIF, data)
    })
    .catch(err => {
      console.log("err", err)
      Notifications.error("Erreur lors du changement de référent")

    })

  }


  sendProgress(chapter_id, progress) {

    const key = `Progress.${chapter_id}.${AppState.vertxClient.UserData.getUserID()}`
    const value = {
      progress,
      pseudo: AppState.vertxClient.UserData.getPseudo() // NOTE ptet je le récupère en dynamique au cas ou il change entre temps
    }

    const overwrite = true

    return new Promise( (resolve, reject) => {
      AppState.vertxClient.GroupCustomDataSetItem(this.groupId, key, value, !overwrite, (success, msg, data) => {
        if(success) {
          resolve()
        }
        else {
          reject("Team sendProgress " + msg)
        }
      })
    })
    // ici on envoie la clé chapter_id.user_id
    // et en valeur { progress_value: 0.333, pseudo }
  }


  fetchClassement(pageSize, pageOffset, sortFields, projection) {
    let params = {
      GenericFilters: { "CustomData.Infos": {$exists: true}}, // permet de virer les groupes "referents.[...]"
      SortFields : sortFields,
      PageSize: pageSize,
      PageOffset: pageOffset,
      Projection: projection ,
      FindIndexes: {
        FullName: [AppState.team.groupId]
      }
    }

    return new Promise( (resolve, reject) => {
      AppState.vertxClient.GroupQuery(params, (success, msg, data) => {
        console.log("GroupQuery", success, msg, data)
        if(success) {
          resolve(data)
        }
        else {
          reject(msg)
        }


      })

    })


  }

}
