import { action, makeAutoObservable, makeObservable, observable } from "mobx"
import DataLoading from "react-sdk/DataLoading"
import Texts from "react-sdk/Texts"

import { Meeting } from "react-sdk/Vertx/MeetingsApi/Meeting"
import MeetingsApi from "react-sdk/Vertx/MeetingsApi/MeetingsApi"
import { clamp, getUrlVar, makeId, removeUrlVar } from "react-sdk/utils"
// import SoundPlayer from "react-sdk/SoundPlayer";
import Config from "react-sdk/Config"
import ScaleManager from "react-sdk/ScaleManager"

import SequencesManager from "react-sdk/SequencesManager"
import SoundPlayer from "react-sdk/SoundPlayer"
import Analytics from "react-sdk/Vertx/Analytics"

import { Team } from "./Team"

import Dials from "./Dials"
import MinigameFtues from "./Minigames/MinigameFtues"
import Minigames from "./Minigames/Minigames"
import ReaxeAPI from "./ReaxeAPI"
import Reliques from "./Reliques/Reliques"
import SequenceScenario from "./SequenceScenario"
import PopupsManager from "react-sdk/PopupsManager"
import GroupMessages from "./GroupMessages"
import Notifications from "react-sdk/Notifications/Notifications"

import * as Sentry from "@sentry/browser"
import SaveGame from "./SaveGame"
import ObjectiveFile, { states } from "./Objectives/ObjectiveFile"
import {
  PopupNotifChapterEndBtns,
  PopupNotifGroupMessageBtn,
  PopupNotifGroupMessageContent,
  PopupNotifChallengePhotoBtn,
  PopupNotifChallengePhotoContent,
  PopupNotifNouveautesBtn,
  PopupNotifOtherSessionsBtn,
  PopupNotifSessionClosedBtn
} from "@/components/popups/PopupNotif/PopupNotifBtns"

const VertxClient = window.gms.VertxClient

// NOTE je fais ça pour tester, ca serait bien de pouvoir générer ça en auto...
/**
 * @typedef VertxClientDef
 * @type {object}
 * @property {object} UserData
 * @property {function} UserData.getUniverse
 * @property {function} UserData.getPseudo
 * @property {function} UserData.getUserMail
 * @property {function} UserData.getUserID
 */

export const SCREENS = {
  LOADING: "LOADING",
  LOGIN: "LOGIN",
  HOME: "HOME",
  WELCOME: "WELCOME",
  USER: "USER",
  TEAM_PSEUDO: "TEAM_PSEUDO",
  TEAM: "TEAM",
  VIDEO: "VIDEO",
  FIRST_DIAL: "FIRST_DIAL",
  GAME: "GAME",
  TEST_MG: "TEST_MG",
  ECRAN_FINAL: "ECRAN_FINAL"
}

export const POPUPS = {
  TEAM: "TEAM",
  USER: "USER",
  LEADERBOARD: "LEADERBOARD",
  NEW_OBJECTIVE: "NEW_OBJECTIVE",
  COLLECTE: "COLLECTE",
  BOUTIQUE: "BOUTIQUE",
  NOTIF: "NOTIF",
  REPLAY_MG: "REPLAY_MG",
  MINIGAME_STARS: "MINIGAME_STARS",
  GROUPADDUSER: "GROUPADDUSER",
  NOUVEAUTES: "NOUVEAUTES",
  CHALLENGE_PHOTO: "CHALLENGE_PHOTO",
  CHALLENGE_PHOTO_LIST: "CHALLENGE_PHOTO_LIST"
}

class AppState {
  debugMode = false

  test_mg_mode = false

  language = "fr"
  screen = SCREENS.LOADING

  /** @type {VertxClientDef} */
  vertxClient = null
  connected = false

  /** @type {Meeting}*/
  currentMeeting = null
  /** @type {string} */
  collecteActivityId = null
  /** @type {string} */
  teamObjectivesActivityId = null

  /** @type {Team} */
  team
  groupReferent = null

  userStars = null

  reaxe_api = null

  haxeCanvas = null

  mapVisible = true
  getMapState = null // fonction registered par la Map pour qu'on l'appelle d'ici
  loadedMapState = null // le state restauré de la map est stocké ici en attendant que la map puisse l'utiliser
  mapFtueBatimentBoundingBox = null // ceci c'est des coords de BB pour cibler les batiments avec la ftue

  teamObjResolve = null // fonction extérieure optionnelle quand on reçoit la CD des objs d'équipe

  UI_visibilities = {}
  lockHud = false // HACK

  get userIsReferent() {
    return !!this.groupReferent
  }
  constructor() {
    this.debugMode = getUrlVar("debug") !== null

    this.test_mg_mode = getUrlVar("test_mg") !== null

    let h = window.innerHeight
    let w = window.innerWidth
    let finalH = 1920
    let finalW = (w * finalH) / h
    finalW = clamp(850, 1200, finalW)

    ScaleManager.init(finalW, finalH)

    if (window.location.hostname === "rekup-r.com") {
      Sentry.init({
        dsn: "https://c52629f736d240ca963f252e2bb9da77@app.glitchtip.com/7178",
        tracesSampleRate: 0.01,
        release: import.meta.env.VITE_APP_VERSION
      })
    }

    SequencesManager.variables.verbose = false

    DataLoading.getAllFiles(window.CONFIG.requiredFiles, window.CONFIG.root)
      .then(files => {
        Config.init(files.config)

        this.reaxe_api = new ReaxeAPI()
        this.haxeCanvas = document.createElement("canvas")
        window.lime.embed("backend-haxe", this.haxeCanvas, ScaleManager.w, ScaleManager.h, {
          parameters: {
            logLevel: Config.get("haxe_loglevel"),
            gameWidth: ScaleManager.w,
            gameHeight: ScaleManager.h,
            reaxe_api: this.reaxe_api.api
          }
        })

        Texts.init(files.texts, this.language)
        this.dials = new Dials(getUrlVar("skip") !== null)
        this.dials.addDialFile("DialsIntro", files.dials_intro)
        this.dials.addDialFile("DialsEp01", files.dials_episode1)
        this.dials.addDialFile("DialsEp02", files.dials_episode2)
        this.dials.addDialFile("DialsEp03", files.dials_episode3)
        this.dials.addDialFile("DialsEp04", files.dials_episode4)
        this.dials.addDialFile("DialsEp05", files.dials_episode5)
        this.dials.addDialFile("DialsEp06", files.dials_episode6)
        this.dials.addDialFile("DialsEp07", files.dials_episode7)
        this.dials.addDialFile("DialsEp08", files.dials_episode8)
        this.dials.addDialFile("DialsEp09", files.dials_episode9)

        Reliques.init(files.reliques)

        this.soloObjectives = new ObjectiveFile(window.CONFIG.root + window.CONFIG.requiredFiles.objectives)

        this.soloObjectives.objectives.forEach(obj => {
          obj.sourceObjective.available.addPostAction(doneFunc => {
            PopupsManager.queue(POPUPS.NEW_OBJECTIVE, obj, () => {
              SequenceScenario.next()
              doneFunc()
            })
          })

          obj.sourceObjective.completed.addPostAction(doneFunc => {
            console.log("TODO OBJ COMPLETED")
            var reward = obj.sourceObjective.line.get("reward")
            if (reward) {
              this.userSaveStars(parseInt(reward))
            }

            if (!obj.parent) {
              console.log(obj)

              // ici c'est un obj parent == chapitre, on affiche son texte
              /** @type {import("@/components/popups/PopupNotif/PopupNotif").NotifPopupOptions} */
              const data = {
                title: Texts.get("popup-chapter-ended-title-" + obj.id),
                text: obj.sourceObjective.line.get("completedText"),
                Buttons: PopupNotifChapterEndBtns,
                textAlign: "center"
              }
              PopupsManager.queue(POPUPS.NOTIF, data, () => {
                doneFunc()
              })
            } else {
              doneFunc()
            }
          })
        })

        this.teamObjectives = new ObjectiveFile(window.CONFIG.root + window.CONFIG.requiredFiles.team_objectives, true)

        SoundPlayer.init(files.audio)

        Minigames.init(files.minigames, files.minigames_levels, files.qcms, files.dragdrops, files.pointerValider)
        MinigameFtues.init(files.minigames_ftue)

        SequenceScenario.init()
        // SequenceScenario.addFile("SequencesScenario", files.sequences_scenario);
        SequenceScenario.addFile("MainSequence", files.main_sequence)
        SequenceScenario.addFile("Episode1", files.episode1)
        SequenceScenario.addFile("Episode2", files.episode2)
        SequenceScenario.addFile("Episode3", files.episode3)
        SequenceScenario.addFile("Episode4", files.episode4)
        SequenceScenario.addFile("Episode5", files.episode5)
        SequenceScenario.addFile("Episode6", files.episode6)
        SequenceScenario.addFile("Episode7", files.episode7)
        SequenceScenario.addFile("Episode8", files.episode8)
        SequenceScenario.addFile("Episode9", files.episode9)
      })
      .then(() => {
        // pas besoin du charEngine comme on n'utilise que des persos animate
        window.SpeechAPI.waitCharacterEngine(false)
        //window.SpeechAPI.setLogLevel(Config.get("speechapi_loglevel"))

        return new Promise(resolve => {
          window.SpeechAPI.initCharacters(window.CONFIG.root + "characters.char", () => {
            console.log("initCharacters done")
            resolve()
          })
        })
      })
      .then(() => this.handleConnection())
      .then(() => {
        var goto = getUrlVar("goto")
        if (goto) {
          SaveGame.clearSave()
        }
      })
      .then(() => {
        if (this.test_mg_mode) {
          this.setScreen(SCREENS.TEST_MG)
          PopupsManager.open(POPUPS.REPLAY_MG)
        } else {
          if (this.connected && !this.debugMode) {
            this.vertxClient.UserGetSessions((success, msg, data) => {
              console.log("UserGetSessions", success, msg, data)
              if (success) {
                const disconnectOtherSessions = () => {
                  console.log("disconnectOtherSessions")

                  this.vertxClient.UserDisconnectSessions(null, null, (success, msg, data) => {
                    console.log("UserDisconnectSessions", success, msg, data)
                    if (success) {
                      PopupsManager.close(POPUPS.NOTIF)
                    } else {
                    }
                  })
                }

                let other = data.DataArray.filter(s_id => s_id !== data.Session)
                let nb = other.length
                if (nb > 0) {
                  /** @type {import('@/components/popups/PopupNotif/PopupNotif').NotifPopupOptions} */
                  const data = {
                    surtitle: Texts.get("popup-notif-session-other-surtitle"),
                    title: Texts.get("popup-notif-session-other-title"),
                    text: Texts.get("popup-notif-session-other-text"),
                    Buttons: PopupNotifOtherSessionsBtn.bind(null, { handleClick: disconnectOtherSessions })
                  }

                  PopupsManager.open(POPUPS.NOTIF, data)
                }

                // resolve(data.DataArray.length > 1)
              } else {
                console.error("Errueur UserGetSessions : " + msg)
                // reject(msg)
              }
            })
          }

          this.setScreen(SCREENS.HOME)
        }
      })

    makeAutoObservable(this, {
      vertxClient: false,
      reaxe_api: false,
      haxeCanvas: false,
      getMapState: false,
      loadedMapState: false
    })
  }

  handleConnection() {
    let connect =
      getUrlVar("anonymous") !== null
        ? () => this.anonConnect(getUrlVar("anonymous"))
        : () => this.handleUserConnection()

    return connect().then(connected => {
      Analytics.init(this.vertxClient)

      if (connected) {
        this.connected = true

        makeObservable(this.vertxClient.UserData, {
          _Pseudo: observable,
          setPseudo: action
        })

        return this.getUserTeamInfo().then(() => this.connectToGlobalMeeting())
      }
    })
  }

  anonConnect(anonId) {
    console.log("anonConnect", anonId)
    const { universe, app, url, port } = window.CONFIG.vertx
    return new Promise((resolve, reject) => {
      this.vertxClient = VertxClient.Create()
      this.vertxClient.SetUniverse(universe)
      this.vertxClient.SetApplication(app)
      this.vertxClient.SetExternalLog(msg => {
        // console.log("vertxClient => ", msg)
      })

      this.vertxClient.OpenConnection(url, port, connected => {
        console.log("OpenConnection", connected)

        // if(!connected) return location.reload()

        this.vertxClient.UserCreateAnonymousAndConnect(anonId, (success, msg, data) => {
          console.log("UserCreateAnonymousAndConnect", success, msg, data)
          if (success) {
            resolve(true)
          } else {
            if (msg === "USER_ANONYMOUS_ID_ALREADY_EXIST") {
              this.vertxClient.UserConnectPasswordlessWithAnonymousId(universe, app, anonId, (success, msg, data) => {
                if (success) {
                  resolve(true)
                } else {
                }
              })
            }
          }
        })

        //   resolve(connected)
      })
    })
  }

  handleUserConnection() {
    const { universe, app, url, port } = window.CONFIG.vertx

    return new Promise((resolve, reject) => {
      this.vertxClient = VertxClient.Create()

      let user_mail = getUrlVar("user")

      let token = getUrlVar("token")
      if (token) {
        this.vertxClient.SetUserToken(universe, user_mail, null, token)
        removeUrlVar("user")
        removeUrlVar("token")
      } else {
        this.vertxClient.SetUniverse(universe)
      }
      this.vertxClient.SetApplication(app)
      // this.vertxClient.SetAnonymousOnly(true)

      this.vertxClient.SetExternalLog(msg => {
        // console.log("vertxClient => ", msg)
      })

      // en cas d'erreur de connexion au vertx
      this.vertxClient.SIG_SocketConnection.Add((status, msg) => {
        console.log("SIG_SocketConnection", status, msg)
        if (!status) {
          Notifications.error("Erreur de connexion au serveur " + msg)
        }
      })

      this.vertxClient.SIG_SessionStatus.Add((status, msg) => {
        console.log("SIG_SessionStatus", status, msg)
        if (!status && this.connected) {
          /** @type {import('@/components/popups/PopupNotif/PopupNotif').NotifPopupOptions} */
          const data = {
            surtitle: Texts.get("popup-notif-session-closed-surtitle", { fallback: null }),
            title: Texts.get("popup-notif-session-closed-title"),
            text: Texts.get("popup-notif-session-closed-text")
          }

          PopupsManager.close(POPUPS.NOTIF)
          PopupsManager.open(POPUPS.NOTIF, data)
        }
      })

      // ces 2 SIG ne nous intéressent pas trop...
      this.vertxClient.SIG_Info.Add((message_name, msg) => {
        // TODO ici on peut écouter et voir si une autre session s'ouvre, auquel cas mettre en pause
        // TODO ici on peut écouter et voir si une autre session se ferme, auquel cas enlever la pause
      })
      // this.vertxClient.SIG_ReconnectionFailed.Add((status) => {})

      this.vertxClient.OpenConnection(url, port, connected => {
        resolve(connected)
      })
    })
  }

  loginWithCode(universe, app, email, code) {
    return new Promise((resolve, reject) => {
      this.vertxClient.UserConnectPasswordlessWithCode(universe, app, email, code, (success, data, msg) => {
        if (success) {
          this.connected = true
          makeObservable(this.vertxClient.UserData, {
            _Pseudo: observable,
            setPseudo: action
          })
          resolve()
        } else {
          reject(msg)
        }
      })
    })
      .then(() => this.getUserTeamInfo())
      .then(() => this.connectToGlobalMeeting())
  }

  getUserTeamInfo() {
    if (getUrlVar("anonymous") !== null) {
      this.team = new Team(getUrlVar("group") || "magasins.test_empty_group")
      this.groupReferent = true // permet de hack le fait qu'on soit référent
      return Promise.resolve() // this.team.fetchInfos()
    }

    return new Promise((resolve, reject) => {
      this.vertxClient.GroupFindUserGroups(this.vertxClient.UserData.getUserID(), (success, msg, groups) => {
        if (success) {
          if (groups.length === 0) {
            reject("Erreur, vous n'appartenez à aucune équipe, merci de contacter votre référent")
          }

          this.groupReferent = groups.find(g => g.startsWith("referents."))
          let groupMagasin = groups.find(g => g.startsWith("magasins."))
          resolve(groupMagasin)
        } else {
          console.log("GroupFindUserGroups ERROR", msg)
          reject(msg)
        }
      })
    })
      .then(groupId => {
        this.team = new Team(groupId)
        return this.team.fetchInfos()
      })
      .catch(err => {
        Notifications.error(err)
      })
  }

  connectToGlobalMeeting() {
    return new Promise((resolve, reject) => {
      // ici on se connecte au meeting !!!!
      let code = window.CONFIG.meetingCode
      let pseudo = this.vertxClient.UserData.getPseudo

      MeetingsApi.init(this.vertxClient)

      MeetingsApi.meetingConnectWithcode(code, pseudo)
        .then(m => {
          this.currentMeeting = m

          // this.currentMeeting.onMeetingMessage = message => {
          //   console.log("New meeting message", message)
          // }

          console.log("connected to meeting")
        })
        .then(() => this.currentMeeting.getActivities())
        .then(() => (this.collecteActivityId = this.currentMeeting.rootActivity.NetID))
        .then(() => console.log("this.collecteActivityId ", this.collecteActivityId))
        .then(() => {
          let teamObjAct = this.currentMeeting.findActivity(a => a.Type === "Custom.RekupoObjectifs")
          if (teamObjAct) this.teamObjectivesActivityId = teamObjAct.NetID
        })
        .then(() => console.log("this.teamObjectivesActivityId ", this.teamObjectivesActivityId))
        .then(() => resolve())
    })
  }

  initTeamObjectivesState() {
    if (getUrlVar("anonymous") !== null) return Promise.resolve()
    return new Promise((resolve, reject) => {
      if (!this.teamObjectivesActivityId) {
        return resolve()
      }

      this.vertxClient.GroupCustomDataGetItem(this.team.groupId, "Objectives", (success, msg, data) => {
        if (!success || data === null) {
          this.teamObjResolve = resolve
          this.currentMeeting.customActivityCommand("RefreshObjectives", this.teamObjectivesActivityId, false, {
            Group: this.team.groupId
          })
        } else {
          let d = data[this.teamObjectives.fileId]
          this.teamObjectives.stateFromJson(d, true)

          resolve()
        }
      })
    })
  }

  setScreen(id) {
    Analytics.changeScreenEvents(this.screen, id)
    this.screen = id
  }

  userGetStars() {
    return new Promise((resolve, reject) => {
      this.vertxClient.UserCustomDataGetItem("rekupr.stars", (success, msg, data) => {
        console.log("UserCustomDataGetItem", success, msg, data)
        if (success) {
          let stars = JSON.parse(data.DataArray[0]) || 0
          resolve(stars)
        } else {
          reject("Error get user custom data: rekupr.stars " + msg)
        }
      })
    }).catch(err => console.error(err))
  }

  userSaveStars(stars, increment = true) {
    return this.userGetStars().then(saved_stars => {
      if (increment) {
        this.userStars = saved_stars + stars
      } else {
        this.userStars = stars // ici on écrase
      }

      this.vertxClient.UserCustomDataSetItem("rekupr.stars", this.userStars, false, (success, msg, data) => {
        console.log("UserCustomDataSetItem", success, msg, data)
        if (success) {
          console.log("Update userStars ok")
        } else {
          console.error("Error set user custom data: rekupr.stars " + msg)
        }
      })
    })
  }

  onMapReady() {
    if (this.test_mg_mode) return
    this.mapVisible = true

    // quick access to specific episode
    var goto = getUrlVar("goto")
    if (goto) {
      SequenceScenario.playSequence("MainSequence", goto)
    } else {
      GroupMessages.init()

      GroupMessages.fetch(false).then(unreadMessages => {
        if (unreadMessages && unreadMessages.length > 0) {
          // ici on affiche en prio les messages admin-groupmessages
          console.log("unreadMessages", unreadMessages)
          let adminMessages = unreadMessages.filter(m => m.content.msgObject !== undefined)

          let queue = []
          let ids_not_nouveautes = []

          if (adminMessages.length > 0) {
            adminMessages.forEach(m => {
              let message = m.content
              let text = message.msgText
              let Content = null
              let reward = message?.rewards?.[0]
              if (reward?.id === "points" && reward?.value > 0) {
                Content = PopupNotifGroupMessageContent.bind(null, { jetons: reward.value })
              }
              /** @type {import("@/components/popups/PopupNotif/PopupNotif").NotifPopupOptions} */
              let data = {
                title: message.msgObject,
                text,
                Content,
                image_url: "./data/images/popup_notif_icons/popup_notif_message.png",
                Buttons: PopupNotifGroupMessageBtn
              }

              queue.push(data)
              ids_not_nouveautes.push(m._id)
            })
          }

          let cpMessages = unreadMessages.filter(m => m.content.ChallengePhoto !== undefined)
          if (cpMessages.length > 0) {
            cpMessages.forEach(m => {
              let { consigne, start, activityId, endDate } = m.content.ChallengePhoto

              /** @type {import("@/components/popups/PopupNotif/PopupNotif").NotifPopupOptions} */
              let data = {
                title: consigne,
                surtitle: Texts.get("popup-notif-challenge-photo-surtitle"),
                text: start
                  ? Texts.get("popup-notif-challenge-photo-text")
                  : Texts.get("popup-notif-challenge-photo-text-finished"),
                image_url: `./${window.CONFIG.root}/images/popup_notif_icons/popup-notif-camera.png`,
                Content: PopupNotifChallengePhotoContent.bind(null, { date_ts: endDate }),

                // onClose: () => PopupsManager.close(POPUPS.NOTIF),
                Buttons: PopupNotifChallengePhotoBtn.bind(null, { start }),

                onClosePopupToOpen: POPUPS.CHALLENGE_PHOTO,
                onClosePopupToOpenData: { activityNetId: activityId }
              }

              queue.push(data)
              ids_not_nouveautes.push(m._id)
            })
          }

          GroupMessages.markAsRead(ids_not_nouveautes, true)
          let otherMessages = unreadMessages.filter(m => !ids_not_nouveautes.includes(m._id))

          if (otherMessages.length > 0) {
            /** @type {import('@/components/popups/PopupNotif/PopupNotif').NotifPopupOptions} */
            const data = {
              title: Texts.get("popup-nouveautes-notif-title", { mustacheVars: { nb: unreadMessages.length } }),
              text: Texts.get("popup-nouveautes-notif-text"),
              image_url: "./data/images/popup_notif_icons/popup_notif_cloche.png",
              Buttons: PopupNotifNouveautesBtn,

              onClosePopupToOpen: POPUPS.NOUVEAUTES
            }
            queue.push(data)
          }

          const processQueue = i => {
            console.log("processQueue", i, queue)
            if (i < queue.length) {
              let data = queue[i]
              PopupsManager.open(POPUPS.NOTIF, data, () => {
                if (data.onClosePopupToOpen) {
                  PopupsManager.open(data.onClosePopupToOpen, data.onClosePopupToOpenData || null, () => {
                    setTimeout(() => {
                      processQueue(i + 1)
                    }, 10)
                  })
                } else {
                  setTimeout(() => {
                    processQueue(i + 1)
                  }, 10)
                }
              })
            } else {
              SequenceScenario.playSequence("MainSequence", "root")
            }
          }

          processQueue(0)

          // PopupsManager.open(POPUPS.NOTIF, data, () => {
          //   PopupsManager.open(POPUPS.NOUVEAUTES, null, () => {
          //     // on joue la séquence scenario "root"
          //     SequenceScenario.playSequence("MainSequence", "root")
          //   })
          // })
        } else {
          // on joue la séquence scenario "root"
          SequenceScenario.playSequence("MainSequence", "root")
        }
      })
    }

    this.getMapState = () => {
      return this.reaxe_api.api.registeredFunctions.stateToJson()
    }
  }
}

export default new AppState()
