import AppState, { POPUPS, SCREENS } from '@/js/AppState';
import Button from '@cmp/shared/Button/Button';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import Texts from 'react-sdk/Texts';
import classes from './ScreenHome.module.css';

import logo_ecodds from "@/images/logo_ecodds.png";
import logo_rekupo from "@/images/logo_rekupo.png";
import logo_ludicius from "@/images/logo_ludicius.png";
import HomeScreens from '@scr/_layouts/HomeScreens/HomeScreens';
import Config from 'react-sdk/Config';
import PopupsManager from 'react-sdk/PopupsManager';
import SoundPlayer from 'react-sdk/SoundPlayer';
import SaveGame from '@/js/SaveGame';

const ScreenHome = () => {
  const [loading, setLoading] = useState(false);
  const [hasSave, setHasSave] = useState(false);


  // gestion de la connexion et passage à l'écran suivant
  function handleClick() {
    setLoading(true)
    if(AppState.connected) {
      SaveGame.load()
      .then(() => {
        if(SaveGame.getState("sequenceIntroVue") || AppState.debugMode) {
          AppState.setScreen(SCREENS.GAME)
        } else {
          AppState.setScreen(SCREENS.WELCOME)
        }
      })
    }
    else {
      AppState.setScreen(SCREENS.LOGIN)
    }
  }

  // check s'il y a une sauvegarde
  useEffect(() => {
    if(AppState.connected) {
      SaveGame.hasSave()
      .then((id) => {
        console.log("id", id)
        setHasSave(!!id)

        if(AppState.debugMode) {
          handleClick()
        }
      })
    }

    SoundPlayer.playSound("music_LP")
  }, []);


  function handleClearSave() {

    const clear = () => {
      SaveGame.clearSave()
      .then(() => {
        setHasSave(false)
        PopupsManager.close(POPUPS.NOTIF)
      })
    }


    /** @type {import('@/components/popups/PopupNotif/PopupNotif').NotifPopupOptions} */
    const data = {
      title: Texts.get("popup-delete-save-title"),
      text: Texts.get("popup-delete-save-text"),
      Buttons: () => <Button onClick={clear}>{Texts.get("popup-delete-save-button")}</Button>,
      onClose: () => PopupsManager.close(POPUPS.NOTIF)
    }

    PopupsManager.open(POPUPS.NOTIF, data)
  }

  if(AppState.connected && !AppState.team) return null

  return (
    <HomeScreens text={Texts.get("home-content")} >

      <div className={classes.button_ctnr}>
        <Button disabled={loading} className={classes.button} onClick={handleClick} name="startButton">
          {AppState.connected ? Texts.get("home-button-play") : Texts.get("home-button-login")}
        </Button>

        {!loading && hasSave && Config.get("show_deletesave_btn") &&
        <a variant='secondary small' onClick={handleClearSave}>
          {Texts.get("home-delete-savegame")}
        </a>}
      </div>

      <div className={classes.logos_wrapper}>
        <div className={classes.logos}>
          <div><img src={logo_ludicius} alt="Logo Ludicius" /></div>
          <div><img src={logo_rekupo} alt="Logo Rekupo" /></div>
          <div><img src={logo_ecodds} alt="Logo Ecodds" /></div>
        </div>
      </div>
    </HomeScreens>








  );
};

export default  observer(ScreenHome)
