// petite classe pour gérer plus clairement les messages et notifs

import { makeObservable, observable, toJS } from "mobx"
import AppState, { POPUPS } from "./AppState"
import PopupsManager from "react-sdk/PopupsManager"
import Collectes, { COLLECTE_STATUS } from "./Collectes"
import Texts from "react-sdk/Texts"
import { PopupNotifGroupMessageBtn, PopupNotifGroupMessageContent, PopupNotifImReferentBtn, PopupNotifNewCollecteBtns, PopupNotifNewCollecteBtnsEstim, PopupNotifNewCollecteBtnsOK, PopupNotifChallengePhotoBtn, PopupNotifChallengePhotoContent } from "@/components/popups/PopupNotif/PopupNotifBtns"
import Notifications from "react-sdk/Notifications/Notifications"
import { objectSetDeepValue } from "react-sdk/utils"

class GroupMessages {

  unreadMessages = []

  constructor() {
    this.handleGroupMessage = this.handleGroupMessage.bind(this)

    makeObservable(this, {
      unreadMessages: observable
    })
  }

  init() {
    AppState.vertxClient.SIG_GroupMessage.Add(this.handleGroupMessage)
  }


  /**
   * Methode appelée à l'arrivée dans le jeu pour récupérer les groupmessages arrivés pendant notre absence
   * Elle est censée être suivie par un affichage de la popup de nouveautés en cas de groupmessages
   * @returns {Promise}
   */
  fetch(all = false) {

    return new Promise( (resolve, reject) => {
      AppState.vertxClient.GroupMessageGetIds(AppState.team.groupId, all, (success, msg, data) => {
        if(success) {
          console.log("GroupMessageGetIds", data)
          let dataObj = {}
          data.forEach(msg => {
            let id = Object.keys(msg)[0]
            dataObj[id] = msg[id]
          })
          let ids = Object.keys(dataObj)
          if (ids.length > 0) {
          //   // ici on a récupére des ids de messages stockés non lus, on va donc les chercher
            this.pullMessages(ids)
            .then(messages => {
              // ici on recombine les datas avec les messages reçus
              let combined_messages = messages.map(msg => {
                let metadata = dataObj[msg._id]
                return {...msg, ...metadata}
              })
              console.log("combined_messages", combined_messages)
              resolve(combined_messages)

            })


            .catch(err => {
              console.error("Erreur lors de la récupération des messages de groupe : " + err)
            })

          }
          else {
            resolve()
          }
        }
        else {
          console.error("Erreur lors de la récupération des IDS des messages de groupe : " + msg)
          Notifications.error(msg)
          reject(msg)
        }
      })

    })
  }


  /**
   * Metohde appelée automatiquement quand on est connecté au jeu et qu'un message de groupe arrive
   * c'est ici qu'on reagit en live à ces messages (ex : modifier la valeur des jetons, afficher une popup de notif, etc)
   * @param {*} messageType
   * @param {*} groupMessage
   */
  handleGroupMessage(messageType, groupMessage) {

    console.log("groupMessage", messageType, groupMessage)


    if(messageType === "StoredMessage") {
      // ici on a une notif de message
      // on n'a pas le contenu, allons le chercher
      const {GroupFullName, MsgID} = groupMessage

      if(GroupFullName && MsgID) {
        this.pullMessage(MsgID, GroupFullName)
        .then((fullMessage) => {

          if (fullMessage === undefined)
            return


          console.log("new group message: ", fullMessage)
          let message = fullMessage.content

          if(message?.NewCollectInfo) {
            // Ici aller chercher les infos de collecte ? histoire de préciser le message
            this.unreadMessages = [...this.unreadMessages, fullMessage]


            Collectes.getSingleCollecte(message.NewCollectInfo)
            .then(collecte => {

              const date = collecte.date_confirmation_DE_toLocale


              let surtitle = Texts.get("collecte-surtitle", {mustacheVars: date})
              let title = ""
              let text = ""


              let Button //= PopupNotifNewCollecteBtnsOK

              if(collecte.statut_DE === COLLECTE_STATUS.REFUSEE) {
                title = Texts.get("collecte-title-refusee")

              }
              else if(collecte.statut_DE === COLLECTE_STATUS.SUPPRIMEE) {
                title = Texts.get("collecte-title-supprimee")
              }

              else {

                if(!collecte.total_weight) {

                  title = Texts.get("collecte-title-nouvelle")

                  if(collecte.Estimation) {
                    text = Texts.get("collecte-estim-done", {mustacheVars: {poids: collecte.EstimationKG} })
                  }
                  else {
                    if(AppState.userIsReferent) {
                      text = Texts.get("collecte-estim-todo-referent")
                      Button = PopupNotifNewCollecteBtnsEstim.bind(null, {collecte})
                    }
                    else {
                      text = Texts.get("collecte-estim-todo-joueur")
                    }
                  }
                }
                else {
                  title = Texts.get("collecte-title-finalisee")
                  text = Texts.get("collecte-pesee", {mustacheVars: {poids: collecte.total_weight * 1000}})

                  if(collecte.Estimation) {
                    text += Texts.get("collecte-pesee-estimee", {mustacheVars: {poids: collecte.EstimationKG}})
                  }
                  else {
                    text += Texts.get("collecte-pesee-non-estimee")

                  }

                }
              }

              if(collecte.conformance_status === "NONCONFORME") {
                text += Texts.get("collecte-non-conforme")
              }
              else if(collecte.conformance_status === "CONFORME") {
                text += Texts.get("collecte-conforme")
              }
              /** @type {import("@/components/popups/PopupNotif/PopupNotif").NotifPopupOptions} */
              const datas = {
                surtitle,
                title,
                text,

                onClose: !Button ? () => PopupsManager.close(POPUPS.NOTIF) : null,
                Buttons: Button
              }
              PopupsManager.queue(POPUPS.NOTIF, datas)
            })
          }
          else if (message?.ObjectiveAvailable) {

            let obj = AppState.teamObjectives.getObjective(message.ObjectiveAvailable)

            // let obj = ObjectivesLib.getObjective(message.ObjectiveAvailable, window.CONFIG.root + window.CONFIG.requiredFiles.team_objectives)

            let objIndex = obj.parent.children.indexOf(obj)

            let firstChild = objIndex === 0
            let text = undefined

            if (!firstChild) {
              let prevObj = obj.parent.children[objIndex - 1]
              text = prevObj.sourceObjective.line.get("completedText") + "<br/><br/>Nouvelle étape :<br/>" + obj.sourceObjective.line.get("descriptionText")
            }
            else {
              text = obj.sourceObjective.line.get("descriptionText")
            }

            let datas = {
              title: !firstChild ? Texts.get("popup-notif-teamobj-step-start-title") : Texts.get("popup-notif-teamobj-start-title"),
              surtitle: obj.parent.name + (firstChild ? "" : (" - Lvl. " + (objIndex + 1))),
              text: text,
              image_url: obj.parent.sourceObjective.line.get("image"),

              onClose: () => PopupsManager.close(POPUPS.NOTIF),
              //Buttons: PopupNotifNewCollecteBtns.bind(null, {isReferent: AppState.userIsReferent, collecte}), // <-instance d'un component react avec des props
            }
            PopupsManager.queue(POPUPS.NOTIF, datas)

          }
          else if (message?.ObjectiveCompleted) {

            let obj = AppState.teamObjectives.getObjective(message.ObjectiveCompleted)

            // let obj = ObjectivesLib.getObjective(message.ObjectiveCompleted, window.CONFIG.root + window.CONFIG.requiredFiles.team_objectives)
            let datas = {
              title: Texts.get("popup-notif-teamobj-end-title"),
              surtitle: obj.name,
              text: obj.children[obj.children.length - 1].sourceObjective.line.get("completedText"),
              image_url: obj.sourceObjective.line.get("image"),

              onClose: () => PopupsManager.close(POPUPS.NOTIF),
              //Buttons: PopupNotifNewCollecteBtns.bind(null, {isReferent: AppState.userIsReferent, collecte}), // <-instance d'un component react avec des props
            }
            PopupsManager.queue(POPUPS.NOTIF, datas)
          }
          else if (message?.BlasonUnlocked) {

            let DM = succubus.data.DataManager.instance
            let blasonsFile = DM.getFile("data/blasons.data")
            let blasonId = message.BlasonUnlocked
            let blasonLine = blasonsFile.getLine(blasonId)
            if(blasonLine) {

              let datas = {
                title: blasonLine.get("title"),
                surtitle: Texts.get("popup-notif-blason-unlocked-title"),
                text: Texts.get("popup-notif-blason-unlocked-description", {
                  mustacheVars: {
                    blason_description: blasonLine.get("description").evaluate()
                  }
                }),
                image_url: `./${window.CONFIG.root}/images/${blasonLine.get("image")}`,

                onClose: () => PopupsManager.close(POPUPS.NOTIF),
                //Buttons: PopupNotifNewCollecteBtns.bind(null, {isReferent: AppState.userIsReferent, collecte}), // <-instance d'un component react avec des props
              }
              PopupsManager.queue(POPUPS.NOTIF, datas)
            }

          }
          else if (message?.CustomDataInfo?.Info?.Boutique?.Log) {
            // euh on va afficher une notif dans la popup "Nouveautés" tiens, pas une popup
            this.unreadMessages = [...this.unreadMessages, fullMessage]

            AppState.reaxe_api.toHaxe("map", "NotifyPoi", "UIpoi3_classement", () => { })
            AppState.reaxe_api.toHaxe("map", "NotifyPoi", "UIpoi4_boutique", () => { })
          }
          else if (message?.PeriodChanged) {
            // pareil ici
            this.unreadMessages = [...this.unreadMessages, fullMessage]


          }
          else if (message?.ChallengePhoto) {
            /** @type {import("@/components/popups/PopupNotif/PopupNotif").NotifPopupOptions} */

            let {consigne, start, activityId, endDate} = message.ChallengePhoto

            let datas = {
              title: consigne,
              surtitle: Texts.get("popup-notif-challenge-photo-surtitle"),
              text: start ? Texts.get("popup-notif-challenge-photo-text") : Texts.get("popup-notif-challenge-photo-text-finished"),
              image_url: `./${window.CONFIG.root}/images/popup_notif_icons/popup-notif-camera.png`,
              Content: PopupNotifChallengePhotoContent.bind(null, {date_ts: endDate}),
              Buttons: PopupNotifChallengePhotoBtn.bind(null, {start}),
            }
            PopupsManager.queue(POPUPS.NOTIF, datas, () => {
              PopupsManager.open(POPUPS.CHALLENGE_PHOTO, {activityNetId: activityId})
            })

          }
          else if (message?.msgObject && message?.msgText) {
            // ici on a reçu un GroupMessage envoyé depuis l'app SGMS
            console.log("MsgID", MsgID)
            let text = message.msgText

            let Content = null

            let reward = message?.rewards?.[0]

            if(reward?.id === "points" && reward?.value > 0) {
              Content = PopupNotifGroupMessageContent.bind(null ,{jetons: reward.value})
            }

            /** @type {import("@/components/popups/PopupNotif/PopupNotif").NotifPopupOptions} */
            let data = {
              title: message.msgObject,
              text,
              Content,
              image_url: "./data/images/popup_notif_icons/popup_notif_message.png",
              Buttons: PopupNotifGroupMessageBtn
            }
            PopupsManager.queue(POPUPS.NOTIF, data, () => {
              this.markAsRead([MsgID])
            })
          }
        })
      }

    }

    if(messageType === "CustomData") {
      const {Command, Key, Value} = groupMessage

      if(Command === "SetItem") {
        if(Key ===  "Infos.teamPseudo") {
          AppState.team.teamPseudo = Value
        }

        if(Key ===  "Points") {
          AppState.team.coins = Value
        }

        if(Key ===  "Objectives") {

          let d = Value[AppState.teamObjectives.fileId]
          AppState.teamObjectives.stateFromJson(d, true)

          if(AppState.teamObjResolve) {
            AppState.teamObjResolve()
            AppState.teamObjResolve = null
          }
        }


        if(Key.startsWith("Progress")) {
          objectSetDeepValue(AppState.team.progress, Key.replace("Progress.", ""), Value);
        }


      }
      else if(Command === "IncrementNumericalValue") {
        if(Key ===  "Points") {
          AppState.team.coins += Value
        }
      }


    }


    if(messageType === "User") {
      const {Command, UserID, GroupFullName} = groupMessage

      const my_id = AppState.vertxClient.UserData.getUserID()

      // j'ai été ajouté au group referents
      if(Command === "UserAdd" && GroupFullName.includes("referents.")) {

        if(UserID === my_id) {
          AppState.groupReferent = GroupFullName
          /** @type {import("@/components/popups/PopupNotif/PopupNotif").NotifPopupOptions} */
          const data = {
            surtitle: Texts.get("popup-notif-confirm-changement-referent-surtitle"),
            title: Texts.get("popup-notif-confirm-changement-referent-title"),
            text: Texts.get("popup-notif-confirm-changement-referent-text"),
            Buttons: PopupNotifImReferentBtn
          }
          PopupsManager.queue(POPUPS.NOTIF, data)
        }

        AppState.team.referentsIds = [...AppState.team.referentsIds, UserID]




      }
      // j'ai été enlevé du group referents
      if(Command === "UserRemove" && GroupFullName.includes("referents.")) {
        if(UserID === my_id) {
          AppState.groupReferent = null
        }

        AppState.team.referentsIds = AppState.team.referentsIds.filter(id => id !== UserID)
      }
    }

  }



  pullMessage(messageId, groupFullName = AppState.team.groupId) {
    return new Promise( (resolve, reject) => {
      this.pullMessages([messageId], groupFullName)
      .then((messages) => {
        if (messages.length > 0)
          resolve(messages[0])
        else
          resolve(undefined)
      })
      .catch((err) => reject(err))
    })
  }

  /**
   *
   * @type {Array<string>} */

  /**
   * Permet de pull les messages avec les IDs indiqués et de recevoir uniquement les messages à afficher =>
   * un filtre est effectué pour éliminer ce qui ne doit pas etre affiché (update lib d'objectifs ou certains nouveaux objectifs/objectifs terminés). Les messages non-pertinents sont marqués comme lus
   * @param {Array<string>} messagesIds
   * @param {string} groupFullName
   * @returns un tableau de messages (objet {id, content}) filtrés
   */
  pullMessages(messagesIds, groupFullName = AppState.team.groupId) {

    return new Promise( (resolve, reject) => {
      AppState.vertxClient.GroupMessagePull(groupFullName, messagesIds, (success, msg, data) => {
        // console.log("GroupMessagePull", data)
        if(success) {
          try {

            let messages = []
            let readIds = []

            for (const [id, content] of Object.entries(data)) {

              if(this.filterIncomingMessage(content)) {
                messages.push({_id: id, content})
              }
              else {
                readIds.push(id)
              }
            }
            // console.log("pullMessages : ", messages, readIds)
            this.markAsRead(readIds)
            .finally(() => resolve(messages))

          }
          catch(err) {
            reject(err)
          }

        } else {
          reject(msg)
        }
      })
    })
  }

  /**
   * Renvoie un bool en fonction de si on est intéressé par le message ou non
   * @param {*} message
   * @returns {boolean}
   */
  filterIncomingMessage(messageContent) {

    if (messageContent?.ObjectiveAvailable) {
      let obj = AppState.teamObjectives.getObjective(messageContent.ObjectiveAvailable)
      // un obj devient available, on ne garde sa notif que si c'est un enfant...
      if(obj?.parent) return true
      return false
    }

    if (messageContent?.ObjectiveCompleted) {
      let obj = AppState.teamObjectives.getObjective(messageContent.ObjectiveCompleted)
      // on montre uniquement les messages d'objectifs completed pour les objectifs roots
      if(!obj?.parent) return true
      return false
    }


    // on ne gere les messages d'info uniquement pour la boutique
    if (messageContent?.CustomDataInfo) {
      if(messageContent?.CustomDataInfo?.Info?.Boutique?.Log) return true
      return false
    }

    return true
  }

  markAsRead(ids, value = true) {
    // console.time("Mark as read: " + ids.length)
    return new Promise( (resolve, reject) => {
      if(ids.length === 0) {
        resolve()
      }
      else {
        AppState.vertxClient.GroupMessageMarkAsRead(ids, value, (success, msg, data) => {
          // console.timeEnd("Mark as read: " + ids.length)
          console.log("GroupMessageMarkAsRead : success, msg, data", success, msg, data)
          if(success) {
            resolve()
          } else {
            reject()
          }
        })
      }
    })
  }


}

export default new GroupMessages()