import React, {useState, useEffect} from 'react'

import classes from './DebugHud.module.css'
import PopupsManager from 'react-sdk/PopupsManager';
import AppState, { POPUPS } from '@/js/AppState';
import SequenceScenario from '@/js/SequenceScenario';
import Reliques from '@/js/Reliques/Reliques';
import { PopupNotifGroupMessageBtn, PopupNotifGroupMessageContent } from '@/components/popups/PopupNotif/PopupNotifBtns';

const DebugHud = () => {

  useEffect(() => {

  // setTimeout(() => {
  //   PopupsManager.open(POPUPS.CHALLENGE_PHOTO_LIST)
  // }, 500);

  }, []);

  return (
    <div className={classes.DebugHud}>
      <details>
        <summary>DEBUG</summary>
        <div>

          {/* {AppState.currentMeeting.rootActivity.Children
          .filter(act => act.Type === "Custom.RekupoPhoto")
          .map(act => {
            return <button style={{background: "pink"}} onClick={() => PopupsManager.open(POPUPS.CHALLENGE_PHOTO, {activityNetId: act.NetID})}>Challenge photo: {act.InitData.Consigne}</button>
          })} */}
          <button style={{background: "pink"}} onClick={() => PopupsManager.open(POPUPS.CHALLENGE_PHOTO_LIST)}>Popup challenge photo list</button>
          <button onClick={() => PopupsManager.open(POPUPS.TEAM)}>Show team page</button>
          <button onClick={() => PopupsManager.open(POPUPS.USER)}>Show user page</button>
          <button onClick={() => PopupsManager.open(POPUPS.LEADERBOARD)}>Show leaderboard page</button>
          <button onClick={() => PopupsManager.open(POPUPS.REPLAY_MG)}>Show Replay MG</button>
          <button onClick={() => PopupsManager.open(POPUPS.BOUTIQUE)}>Show Boutique</button>
          <button onClick={() => PopupsManager.open(POPUPS.NOUVEAUTES)}>Show popup nouveautés</button>
          <button onClick={() => SequenceScenario.playSequence("dis_bureauTrash")}>OPEN BUREAU TRASH</button>
          <button onClick={() => SequenceScenario.playSequence("dis_magasinPeinture")}>OPEN MAGASIN PEINTURE</button>
          <button onClick={() => SequenceScenario.playSequence("dis_universite")}>OPEN UNIVERSITE</button>
          <button onClick={() => SequenceScenario.playSequence("dis_laboHope")}>OPEN LABO</button>
          <button onClick={() => Reliques.all[0].unlock()}>unlock relique 0</button>
          <button onClick={() => SequenceScenario.next()}>SequenceScenario.next</button>
          <button onClick={() => {
            let data = {
              title: "Ici le titre",
              text: "Ici le contenu...",
              Content: PopupNotifGroupMessageContent.bind(null ,{jetons: 40}),
              image_url: "./data/images/popup_notif_icons/popup_notif_message.png",
              Buttons: PopupNotifGroupMessageBtn
            }
            PopupsManager.open(POPUPS.NOTIF, data)
          }}>popup de notif de groupmessage</button>

          <button onClick={() => {
            AppState.vertxClient.GroupMessagePush(
              AppState.team.groupId,
              JSON.stringify({NewCollectInfo: "debug_1724860311354"}),
              (success, msg, data) => {
                console.log("GroupMessagePush", success, msg, data)
              }
            )
          }}>Simuler une nouvelle collecte</button>

          <button onClick={() => {
            AppState.vertxClient.GroupMessagePush(
              AppState.team.groupId,
              JSON.stringify({CustomDataInfo: {Info: {Boutique: {Log: "La commande 123456 a bien été enregistrée"}}}}),
              (success, msg, data) => {
                console.log("GroupMessagePush", success, msg, data)
              }
            )
          }}>Simuler un message de la boutique</button>


          <button onClick={() => {
            AppState.vertxClient.GroupMessagePush(
              AppState.team.groupId,
              JSON.stringify({BlasonUnlocked: "platinum"}),
              (success, msg, data) => {
                console.log("GroupMessagePush", success, msg, data)
              }
            )
          }}>Simuler un gain de blason</button>

          <button onClick={() => {
            AppState.vertxClient.GroupMessagePush(
              AppState.team.groupId,
              JSON.stringify({PeriodChanged: {new: "ete_2024" }
              }),
              (success, msg, data) => {
                console.log("GroupMessagePush", success, msg, data)
              }
            )
          }}>Simuler une notif de période (new seulement)</button>

          <button onClick={() => {
            AppState.vertxClient.GroupMessagePush(
              AppState.team.groupId,
              JSON.stringify({PeriodChanged: {old: "automne_2024", new: "hiver_2024" }
              }),
              (success, msg, data) => {
                console.log("GroupMessagePush", success, msg, data)
              }
            )
          }}>Simuler une notif de période (old+new)</button>


          <button onClick={() => {
            AppState.vertxClient.GroupMessagePush(
              AppState.team.groupId,
              JSON.stringify({ObjectiveCompleted: "estim1"}),
              (success, msg, data) => {
                console.log("GroupMessagePush", success, msg, data)
              }
            )
          }}>Simuler une complétion d'objectif d'équipe ROOT</button>

          <button onClick={() => {
            AppState.vertxClient.GroupMessagePush(
              AppState.team.groupId,
              JSON.stringify({ObjectiveAvailable: "estim1.1"}),
              (success, msg, data) => {
                console.log("GroupMessagePush", success, msg, data)
              }
            )
          }}>Simuler une activation d'objectif d'équipe: 1er CHILD</button>

          <button onClick={() => {
            AppState.vertxClient.GroupMessagePush(
              AppState.team.groupId,
              JSON.stringify({ObjectiveAvailable: "estim1.2"}),
              (success, msg, data) => {
                console.log("GroupMessagePush", success, msg, data)
              }
            )
          }}>Simuler une activation d'objectif d'équipe: 2eme CHILD</button>

          <button onClick={() => {
            AppState.currentMeeting.customActivityCommand("RefreshObjectives", AppState.teamObjectivesActivityId, false, { "Group" : AppState.team.groupId })
          }}>REFRESH TEAM OBJECTIFS</button>

          <button onClick={() => {
            AppState.vertxClient.GroupCustomDataIncrementNumericalValue(AppState.team.groupId, "Points", 100, true, (success, msg, data) => {
              console.log("GroupCustomDataIncrementNumericalValue ", success, msg, data)
            })
          }}>Add 100 Points</button>
          <button onClick={() => {
            AppState.vertxClient.GroupCustomDataIncrementNumericalValue(AppState.team.groupId, "Points", -100, true, (success, msg, data) => {
              console.log("GroupCustomDataIncrementNumericalValue ", success, msg, data)
            })
          }}>Remove 100 Points</button>

          <button onClick={() => {
            AppState.reaxe_api.toHaxe("map", "NotifyPoi", "UIpoi5_mg", () => { })
          }}>Notify MG poi</button>

          <div>SequenceScenario : {SequenceScenario.currentElement?.id}</div>
          <div>STARS : {AppState.userStars}</div>
        </div>



      </details>
    </div>
  );
};

export default DebugHud;
