import React, { useState, useEffect } from "react"

import classes from "./PopupChallengePhotoList.module.css"
import Popup from "@/components/shared/Popup/Popup"
import PopupsManager from "react-sdk/PopupsManager"
import AppState, { POPUPS } from "@/js/AppState"
import Texts from "react-sdk/Texts"
import camera from "./icon-camera.png"
import Button from "@/components/shared/Button/Button"
import { FaChevronRight } from "react-icons/fa6"
import clsx from "clsx"
import { toJS } from "mobx"
import { Activity } from "react-sdk/Vertx/MeetingsApi/Activity"

const NOT_OPENED_YET = 0
const OPENED = 1
const CLOSED = 2

/**
 * @param {object} props
 * @param {Activity} props.activity_challenge
 */
const ChallengeBlock = ({ activity_challenge }) => {
  let consigne = activity_challenge.InitData.Consigne

  let state = activity_challenge.Opened ? OPENED : activity_challenge.CloseTime === 0 ? NOT_OPENED_YET : CLOSED

  let scheduledOpen = new Date(activity_challenge.ScheduledOpeningTime)
  let scheduledClose = new Date(activity_challenge.ScheduledClosingTime)

  let closedTime = !activity_challenge.Opened && new Date(activity_challenge.CloseTime)

  if (state === NOT_OPENED_YET) return null // finalement je cache pour ne pas voir la consigne en avance !

  return (
    <div className={clsx(classes.ChallengeBlock, !activity_challenge.Opened && classes.closed)}>
      <div className={classes.icon}>
        <img src={camera} />
      </div>

      <div className={classes.consigne}>{consigne}</div>

      <div className={classes.enddate}>
        {state === OPENED && (
          <span
            dangerouslySetInnerHTML={{
              __html: Texts.get("challengephoto-header-scheduled-end-date", {
                mustacheVars: {
                  date: scheduledClose.toLocaleDateString(),
                  time: scheduledClose.toLocaleTimeString()
                }
              })
            }}
          />
        )}

        {state === CLOSED && (
          <span
            dangerouslySetInnerHTML={{
              __html: Texts.get("challengephoto-header-real-end-date", {
                mustacheVars: {
                  date: closedTime.toLocaleDateString(),
                  time: closedTime.toLocaleTimeString()
                }
              })
            }}
          />
        )}
      </div>

      <div className={classes.action}>
        {state !== NOT_OPENED_YET && (
          <Button
            variant="contour"
            icon={<FaChevronRight />}
            onClick={() => {
              console.log("open CP", activity_challenge.NetID)

              PopupsManager.open(POPUPS.CHALLENGE_PHOTO, { activityNetId: activity_challenge.NetID })
            }}
          />
        )}
      </div>
    </div>
  )
}

const PopupChallengePhotoList = () => {
  const [activities, setActivities] = useState(null)

  function getActivities() {
    setActivities(null)

    let time = act => act.OpenStartTime || act.ScheduledOpeningTime

    AppState.currentMeeting.getActivities().then(() => {
      let acts = AppState.currentMeeting
        .filterActivities(act => act.Type === "Custom.RekupoPhoto")
        .toSorted((a, b) => {
          if (time(a) < time(b)) return 1
          if (time(a) > time(b)) return -1
          return 0
        })
      setActivities(acts)
    })
  }

  useEffect(() => {
    getActivities()
  }, [])
  return (
    <Popup className={classes.PopupChallengePhotoList} onClose={() => PopupsManager.close(POPUPS.CHALLENGE_PHOTO_LIST)}>
      <div className="popup-title">
        <h3>{Texts.get("popup-challengephoto-list-title")}</h3>
      </div>
      <div className={classes.body}>
        {!activities && <div>Chargement...</div>}
        {activities && activities.map(act => <ChallengeBlock activity_challenge={act} />)}
      </div>
    </Popup>
  )
}

export default PopupChallengePhotoList
