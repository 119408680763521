import { makeAutoObservable, makeObservable, observable } from "mobx"
import classifyPoint from "robust-point-in-polygon"
import { makeId, pickRandom } from "../utils"
import ActivityPedaInstance from "./ActivityPedaInstance"

export class Marker {
  id
  x
  y
  // imgScale
  correct = false

  locked = false

  constructor (x, y, locked = false) {
    this.x = x
    this.y = y
    // this.imgScale = imgScale
    this.id = makeId()

    this.locked = locked
    // makeAutoObservable(this)
  }

  get coordsArray() {
    return [this.x, this.y]
    // return [this.x / this.imgScale, this.y / this.imgScale]
  }
}


export class Feature {
  id

  coords =  []
  correct = false

  constructor(coords, id) {
    this.coords = coords
    if(id) this.id = id
    else this.id = makeId()
  }

  svgPath() {

    let str = ""
    this.coords.forEach(([x, y], index) => {
      if(index === 0 ) {
        str += "M"
      }else {
        str += "L "
      }

      str += `${x} ${y}`
    })

    str += " Z"
    return str
  }

  get centroidCoords() {
    return this.coords.reduce((center, coord, i) => {
      center[0] += coord[0]
      center[1] += coord[1]

      if(i === this.coords.length - 1) {
          center[0] /= this.coords.length
          center[1] /= this.coords.length
      }

      return center
    }, [0, 0])
  }
}

export class PointerValider extends ActivityPedaInstance {
  ID
  Text = null
  image = null

  duration = null

  /** @type {Array<Feature>} */
  features = []

  /** @type {Array<Marker>} */
  markers = []


  imageScale = 1
  constructor(row) {
    super()
    if(row) {
      Object.assign(this, row)
      this.features = this.parseFeatures(this.features)
    }

  }

  fromJson(json) {
    Object.assign(this, json)
    this.features = this.features.map(f => new Feature(f.coords, f.id))
    this.observe()
    return this
  }

  observe() {
    makeObservable(this, {
      markers: observable
    })

  }

  parseFeatures(geojson) {
    try {
      geojson = JSON.parse(geojson)

      const polys = geojson.features
      .map(feature => {
        if(feature.geometry.type === "Polygon") {
          return feature.geometry.coordinates[0].map(([x, y]) => ([Math.abs(x), Math.abs(y)]))
        }
        return null
      })
      .filter(coords => !!coords)
      .map(coords => new Feature(coords))

      return polys

    }
    catch(err) {
      console.error("Erreur dans le parsing des features pour " + this.ID);
      console.log(err);
    }
  }

  addMarker(x, y, locked = false) {
    const m = new Marker(x, y, locked)
    this.markers = [...this.markers, m]
  }

  deleteMarker(m_id) {
    this.markers = this.markers.filter(m => m.id !== m_id)
  }

  getAnswer() {
    // ici il faudrait rendoyer les xy des markers, ou tous les markers
    return this.markers
  }

  get jokerAvailable() {
    return this.features.length > 1 && this.markers.length < this.features.length;
  }

  useJoker() {
    // ici on ajoute un marker sur une zone au pif
    // faut-il qu'on ne le fasse que sur une zone non marquée ?
    let feature = pickRandom(this.features)
    let center = feature.centroidCoords
    this.addMarker(center[0], center[1], true)

    this.jokerUsed = true
  }

  reset() {
    this.markers = []
    this.set = null
    this.features.forEach(f => f.correct = false)
    this.setResult(null)
  }

  localCorrect(normalizedRemainingTime) {
    // NOTE possible que le localCorrect ne fonctionne plus vraiment avec Biznesslot4
    // ca semble dû à des soucis de coords... les points ne sont pas dans les mêmes coords que les zones...
    // NOTE commencé à le fix pour Roadbuilder mais plus besoin du PV donc je laisse pour le moment.

    for (let marker of this.markers) {
      console.log("evaluation marker", marker.id, marker.coordsArray);

      for(let feature of this.features) {

        console.log("evaluation feature", feature.id, feature.coords);
        // ici si la feature est déja "correct" on ne veut pas la changer ni ajouter un marker correct dedans
        if(feature.correct) continue

        let result = classifyPoint(feature.coords, marker.coordsArray )

        console.log("result", result)

        if(result <= 0) {
          marker.correct = true
          feature.correct = true
          continue
        }
      }
    }


    // this.markers.forEach((marker, index) => {
    //   for(let feature of this.features) {
    //     console.log("feature", feature)

    //     var result = classifyPoint(feature.coords, marker.coordsArray )
    //     console.log("result", result)
    //     if(result <= 0) {
    //       marker.correct = true
    //       feature.correct = true
    //       // on s'arrête la pour ce point
    //       continue
    //     }
    //   }
    // })

    // on a une liste de markers
    // on a une liste de features
    // => on compte les features correctes, on ne compte pas les markers faux (ceux en double sur une feature, ou ceux mal placés)

    const nbFeaturesCorrect = this.features.filter(f => f.correct).length

    const normalized_score = nbFeaturesCorrect / this.features.length

    /** @type {import("./ActivityPedaInstance").ActivityResult} */
    let result = {
      is_correct: nbFeaturesCorrect === this.features.length,
      normalized_score

    }

    this.setResult(result)

  }
}


export class PointerValiderFile {
  /** @type {Array<PointerValider>} */
  all = []

  constructor(json, file) {
    let current = null
    json.forEach(row => {
      try {

        if(row.Type === "question" ||row.Type === "pointervalider") {
          current = new PointerValider(row)
          this.all.push(current)
        }

        if(row.Type === "debrief") {
          current.addDebrief(row, null)
        }
        if(row.Type === "debrief_correct") {
          current.addDebrief(row, true)
        }
        if(row.Type === "debrief_incorrect") {
          current.addDebrief(row, false)
        }
      }
      catch(err) {
        console.log("err", err)
        throw "ERREUR CREATION POINTER VALIDER : " + row.ID + " dans " + file
      }
    })
  }
  get(id) {
    return this.all.find(cv => cv.ID === id)
  }

  observeAll() {
    this.all.forEach(cv => cv.observe())
  }
}