import React, { useState, useEffect, useRef } from "react"

import classes from "./Footer.module.css"
import { observer } from "mobx-react-lite"
import ChallengePhoto from "../ChallengePhoto"
import Loader from "react-sdk/components/Loader/Loader"
import clsx from "clsx"
import AppState from "@/js/AppState"
import { toJS } from "mobx"
import { FaArrowLeft, FaCheck, FaLock, FaX } from "react-icons/fa6"
import Texts from "react-sdk/Texts"

const Footer = () => {
  const thumbnailsCtnrRef = useRef(null)

  /**
   *
   * @param {import('../ChallengePhoto').GroupData} thumb
   */
  function getTotalEvaluations(thumb) {
    let total = 0
    if (thumb.NegativeEvaluationCount) total += thumb.NegativeEvaluationCount
    if (thumb.PositiveEvaluationCount) total += thumb.PositiveEvaluationCount

    return total
  }

  return (
    <div className={classes.Footer}>
      {ChallengePhoto.loading && (
        <>
          <div className={clsx(classes.myPicture)}>
            <div className={classes.thumbnail}>
              <div className={classes.loader}>
                <Loader />
              </div>
            </div>
          </div>

          <div className={classes.thumbnails}>
            <div className={classes.lock_icon}>
              <Loader />
            </div>
          </div>
        </>
      )}

      {!ChallengePhoto.loading && (
        <>
          <div className={clsx(classes.myPicture)}>
            <div
              className={clsx(
                classes.thumbnail,
                ChallengePhoto.selectedThumbId === ChallengePhoto.teamId && classes.selected
              )}
              onClick={() => ChallengePhoto.myData && ChallengePhoto.selectThumbnail(ChallengePhoto.myData)}
            >
              {ChallengePhoto.myData && <img src={ChallengePhoto.myData.PhotoMini} />}
              <span>{Texts.get("challengephoto-footer-my-photo")}</span>
            </div>
          </div>

          <div
            className={classes.thumbnails}
            ref={thumbnailsCtnrRef}
            onWheel={e => (thumbnailsCtnrRef.current.scrollLeft += e.deltaY)}
          >
            {!ChallengePhoto.myData && <FaLock className={classes.lock_icon} />}

            {ChallengePhoto.allPhotosEvaluated && (
              <div className={clsx(classes.thumbnails_info)}>{Texts.get("challengephoto-footer-no-photo-to-eval")}</div>
            )}

            {ChallengePhoto.thumbnails.map(thumb => {
              let myValidation = ChallengePhoto.getMyEvaluation(thumb)
              // console.log("myValidation", toJS(myValidation))
              return (
                <div
                  className={clsx(
                    classes.thumbnail,
                    thumb.TeamID === ChallengePhoto.selectedThumbId && classes.selected
                  )}
                  key={thumb._id}
                  onClick={() => ChallengePhoto.selectThumbnail(thumb)}
                >
                  <img src={thumb.PhotoMini} alt="Thumbnail" />

                  <span>
                    {!myValidation && (
                      <div className={classes.thumb_my_validation}>{Texts.get("challengephoto-to-evaluate")}</div>
                    )}

                    {myValidation && (
                      <div className={classes.thumb_my_validation}>
                        {myValidation.Evaluation
                          ? Texts.get("challengephoto-footer-evaluated-yes")
                          : Texts.get("challengephoto-footer-evaluated-no")}
                      </div>
                    )}

                    {myValidation?.Progression && (
                      <div
                        className={clsx(
                          myValidation.Progression > 0
                            ? classes.thumb_positive_validations
                            : classes.thumb_negative_validations
                        )}
                      >
                        {myValidation.Progression}
                      </div>
                    )}

                    {/* {thumb.Validated !== undefined && <div>V ? {thumb.Validated ? <FaCheck style={{color: "lime"}} />: <FaX style={{color: "red"}} />}</div>} */}

                    {thumb.Validated === undefined && myValidation && (
                      <div className={classes.validation_progress}>
                        <progress
                          min={0}
                          max={ChallengePhoto.activity.Progression}
                          value={getTotalEvaluations(thumb)}
                        ></progress>
                      </div>
                    )}
                  </span>

                  {thumb.notified && <div className={classes.thumb_notif}>!</div>}
                </div>
              )
            })}

            {ChallengePhoto.thumbnails.length === 1 && !ChallengePhoto.allPhotosEvaluated && (
              <div className={classes.thumbnails_info}>
                <FaArrowLeft />
                <div>{Texts.get("challengephoto-footer-other-photos")}</div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default observer(Footer)
