import { Meeting } from "./Meeting"


export const ACTIVITY_STATES = {
  DISABLED: "DISABLED",
  ENABLED: "ENABLED",
  OPENED: "OPENED",
  FINISHED: "FINISHED",
}


const meetingBasicFields = ["_id", "MeetingCode", "MeetingCodeDate", "MeetingName", "State", "Config"]


/**
 * @typedef VertxClientDef
 * @type {object}
 * @property {object} UserData
 * @property {function} UserData.getUniverse
 * @property {function} UserData.getPseudo
 * @property {function} UserData.getUserMail
 * @property {function} UserData.getUserID
 */

/**
 * => ici tout ce qui a trait au meeting, mais sans besoin d'être connecté à un meeting en particulier. C'est un singleton
 * => dans Meeting, tout ce qui nécessite la connexion à un meeting ? = Un meeting connecté...
 */
class MeetingsApi {
  /** @type {VertxClientDef} */
  vertxClient = null

  user_id = null

  init(vertxClient) {
    this.vertxClient = vertxClient
  }

  /**
   *
   * @param {string} meeting_id
   * @returns {Promise<Meeting>} le Meeting auquel on vient de se connecter
   */
  meetingConnect(meeting_id, observerMode) {
    return new Promise( (resolve, reject) => {
      this.vertxClient.UserMeetingConnect(meeting_id, observerMode, (success, message, data) => {
        if(success) {
          this.vertxClient.UserGetInfo((success, message, data) => {
            if(success) {
              data = JSON.parse(data.DataArray[0])
              this.user_id = data._id
              this.meetingGetFields(meeting_id, meetingBasicFields)
              .then(data => {
                let m = new Meeting(this.vertxClient, data)
                resolve(m)
              })
            }
            else {
              console.log("UserGetInfo", message)
              reject(message)

            }
          })

        }
        else {
          console.log("UserMeetingConnect", message)
          reject(message)
        }
      })
    })
  }

  /**
   *
   * @param {string} code le code de connexion au meeting (~6 char)
   * @param {string} pseudo si jamais on veut modifier son pseudo avant de se connecter. Note: ça modifie le pseudo en global, peut-être pas super indiqué
   * @returns {Promise<Meeting>} le Meeting auquel on vient de se connecter
   */
  meetingConnectWithcode(code, pseudo, observerMode) {
    return new Promise( (resolve, reject) => {
      if(code === "") {
        reject("error_no_code")
        return
      }
      if(pseudo === "") {
        reject("error_no_username")
        return
      }

      // REFACTOR
      //
    // this.vertxApi.user.updatePseudo(pseudo, ({status, data, statusMessage}) => {
    //   if(status) {
    //     this.vertxApi.user.getConnectedUser().pseudo = pseudo

        this.vertxClient.UserGetInfo((success, msg, data) => {
          if(success) {
            data = JSON.parse(data.DataArray[0])
            this.user_id = data._id

            this.vertxClient.MeetingAddUserWithCode(code, this.user_id, (success, msg, data) => {

              if(success) {
                let meetingId = data.MeetingID
                this.vertxClient.UserMeetingConnect(meetingId, observerMode, (success, msg, data) => {
                  if(success) {
                    this.vertxClient.UserMeetingSetPseudo(meetingId, pseudo, (success, statusMessage, data) => {
                      if(success) {
                        // TODO
                        // this.vertxApi.user.getConnectedUser().pseudo = pseudo
                        this.meetingGetFields(meetingId, meetingBasicFields)
                        .then(data => {
                          let m = new Meeting(this.vertxClient, data)
                          resolve(m)
                        })
                      }
                      else {
                        console.log("ERROR UserMeetingSetPseudo", statusMessage)
                        reject("ERROR UserMeetingSetPseudo")
                      }
                    })
                  }
                  else {
                    console.log("ERROR UserMeetingConnect", msg)
                    reject("ERROR UserMeetingConnect")
                  }

                })
              }
              else {
                console.log("ERROR MeetingAddUserWithCode", msg)
                reject("ERROR MeetingAddUserWithCode")
              }
            })
          }
          else {
            console.log("ERROR UserGetInfo", msg)
            reject("ERROR UserGetInfo")
          }
        })
      // }
    //   else {
    //     console.log("ERROR SET PSEUDO", statusMessage)
    //     reject("ERROR SET PSEUDO")
    //   }
    // })
    })
  }

  meetingConnectLocal(pseudo) {
    return new Promise( (resolve, reject) => {
      this.vertxClient.UserGetInfo((success, msg, data) => {
        if(success) {
          data = JSON.parse(data.DataArray[0])
          this.user_id = data._id

          this.vertxClient.UserMeetingConnect("", false, (success, message, data) => {
            if(success) {
              this.vertxClient.UserMeetingSetPseudo("", pseudo, (success, statusMessage, data2) => {
                // this.vertxApi.user.getConnectedUser().pseudo = pseudo

                if(success) {
                  let m = new Meeting(this.vertxClient, {_id: data.MeetingID})
                  resolve(m)
                }
                else {
                  console.log("Error UserMeetingSetPseudo", statusMessage)
                  reject(statusMessage)
                }
              })
            }
            else {
              console.log("Error UserMeetingConnect", message)
              reject(message)
            }
          })
        }
        else {
          console.log("Error UserGetInfo", msg)
          reject(msg)
        }
      })
    })
  }

  meetingCreate(meeting_name, activitiesFilePath) {

    return new Promise( (resolve, reject) => {
      let meeting_password = ""

      let meeting_config = {
        // Game: {configName: "Standard"},
        // MeetingPeriods: [{ DurationSec: 0 }],
        // Scores: [],
        MeetingFile: activitiesFilePath,
        MeetingType: "TestGameModule", // peut-être pas nécessaire mais bon
        MeetingGameModule: {
          Version: "wip",
          GameToCreate: "GameDefault"
      }
      }

      this.vertxClient.MeetingCreate(meeting_name, meeting_password, JSON.stringify(meeting_config), (success, msg, data) => {
        console.log("MEETING CREATE success, msg, data", success, msg, data)
        if(success) {
          resolve(data)
        }
        else {
          reject(msg)
        }
      })

    })
  }


  /**
   * Permet de charger un json d'activities dans un meeting
   *
   * @param {string} meetingId
   * @param {Object} activities
   * @param {string} parent NetID d'une activité parente, pour y insérer les enfants. Si null : remplace toutes les activities du meeting
   */
  loadActivities(meetingId, activities, parent = null) {
    var cmd = {
      ActivityManager: {
        Command: 'LoadConfig',
        Config : activities,
        Parent: parent
        // TryLoadBackup : true
      }
    }
    return this._sendCommand(meetingId, cmd)
  }

  /**
   * renvoie une liste d'objets, avec les champs ci-dessous
   * @returns {Promise<Array<Object>>}
   */
  getMeetingsList() {

    return new Promise( (resolve, reject) => {

      let key = "AdminUsersId." + this.vertxClient.UserData.getUserID()
      const config = {
        Filter: {
          [key]: {$exists: true},
          "State": {$ne: "DESTROYED"}
        },
        Projection: meetingBasicFields,
        "PageOffset": 0,
        "PageSize": 0
      }

      let collection = this.vertxClient.UserData.getUniverse() + "_Meetings"

      this.vertxClient.AdminQuery(collection, config, (success, msg, data) => {
        console.log("AdminQuery", success, msg, data)
        let meetings = data.List

        resolve(meetings)

      })

    })


    return new Promise( (resolve, reject) => {
      this.vertxClient.UserMeetingGetList((success, msg, data) => {
        if(success) {
          let meetings
          if(data.DataArray) {
            meetings = JSON.parse(data.DataArray[0])
          }
          else {
            meetings = data
          }

          let proms = Object.keys(meetings).map(m_id => {
            return this.meetingGetFields(m_id, meetingBasicFields)
          })

          Promise.all(proms)
          .then(data => resolve(data))
          .catch(err => reject(err))

        }
        else {
          reject(msg)
        }
      })
    })
  }

  meetingDelete(meetingId) {
    return new Promise( (resolve, reject) => {
      this.vertxClient.MeetingDestroy(meetingId, (success, msg, data) => {
        if(success) {
          resolve()
        }
        else {
          reject(msg)
        }
      })
    })
  }

  /**
   *
   * @param {String} meetingId
   * @param {Array<String>} fields
   * @returns
   */
  meetingGetFields(meetingId, fields) {
    return new Promise( (resolve, reject) => {
      this.vertxClient.UserMeetingGetFields(meetingId, fields, (success, msg, data) => {
        if(success) {
          data = JSON.parse(data.DataArray[0])
          resolve(data)
        } else {
          reject(msg)
        }
      })
    })
  }

  // NOTE dommage j'aurais bien aimé garder ça uniquement dans Meeting, mais il y en a aussi besoin ici pour loadActivities :(
  _sendCommand(meeting_id, cmd) {
    return new Promise( (resolve, reject) => {
      this.vertxClient.UserMeetingCommand(meeting_id, "GameModule", JSON.stringify(cmd), (success, msg, data) => {
        // console.log("==================")
        // console.log("SEND COMMAND MEETINGS API", cmd)
        // console.log("success", success)
        // console.log("msg", msg)
        // console.log("data", data)
        // console.log("==================")
        if(success) {
          resolve(data)
        } else {
          reject(msg)
        }
      })
    })
  }

}

export default new MeetingsApi()