import React, {useState, useEffect} from 'react'

import classes from './Notation.module.css'
import btn_non from './btn_non.png'
import btn_oui from './btn_oui.png'
import Button from '@/components/shared/Button/Button'
import { FaChevronLeft } from 'react-icons/fa6'
import ChallengePhoto from '../ChallengePhoto'
import Texts from 'react-sdk/Texts'
import clsx from 'clsx'


const STEP_CHOICE = 0
const STEP_STARS = 1
const STEP_STARS_FINISHED = 2
const STEP_REASON = 3
const STEP_REASON_CONFIRM = 4


const Star = ({filled=false, onHover, onClick}) => {
  return (
    <svg
      onMouseMove={onHover}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="71"
      height="68"
      fill="none"
      viewBox="0 0 71 68"
    >
      {filled &&
      <>
        <path
          fill="url(#paint0_linear_333_8310)"
          d="M30.798 4.335c1.48-4.555 7.924-4.555 9.404 0l4.852 14.934a4.94 4.94 0 0 0 4.703 3.416h15.701c4.79 0 6.781 6.129 2.907 8.944l-12.703 9.23a4.94 4.94 0 0 0-1.797 5.527l4.852 14.933c1.48 4.556-3.733 8.344-7.608 5.528l-12.703-9.229a4.94 4.94 0 0 0-5.812 0l-12.703 9.23c-3.875 2.815-9.089-.973-7.609-5.528l4.853-14.934a4.94 4.94 0 0 0-1.797-5.528L2.636 31.63c-3.874-2.815-1.883-8.944 2.907-8.944h15.701c2.142 0 4.04-1.38 4.703-3.416z"
        ></path>
        <path
          fill="url(#paint1_linear_333_8310)"
          d="M30.798 4.335c1.48-4.555 7.924-4.555 9.404 0l4.852 14.934a4.94 4.94 0 0 0 4.703 3.416h15.701c4.79 0 6.781 6.129 2.907 8.944l-12.703 9.23a4.94 4.94 0 0 0-1.797 5.527l4.852 14.933c1.48 4.556-3.733 8.344-7.608 5.528l-12.703-9.229a4.94 4.94 0 0 0-5.812 0l-12.703 9.23c-3.875 2.815-9.089-.973-7.609-5.528l4.853-14.934a4.94 4.94 0 0 0-1.797-5.528L2.636 31.63c-3.874-2.815-1.883-8.944 2.907-8.944h15.701c2.142 0 4.04-1.38 4.703-3.416z"
        ></path>
        <path
          fill="#FFA757"
          d="M30.798 4.335c1.48-4.555 7.924-4.555 9.404 0l4.852 14.934a4.94 4.94 0 0 0 4.703 3.416h15.701c4.79 0 6.781 6.129 2.907 8.944l-12.703 9.23a4.94 4.94 0 0 0-1.797 5.527l4.852 14.933c1.48 4.556-3.733 8.344-7.608 5.528l-12.703-9.229a4.94 4.94 0 0 0-5.812 0l-12.703 9.23c-3.875 2.815-9.089-.973-7.609-5.528l4.853-14.934a4.94 4.94 0 0 0-1.797-5.528L2.636 31.63c-3.874-2.815-1.883-8.944 2.907-8.944h15.701c2.142 0 4.04-1.38 4.703-3.416z"
        ></path>
        <path
          fill="#FF9A37"
          d="M30.798 4.335c1.48-4.555 7.924-4.555 9.404 0l4.852 14.934a4.94 4.94 0 0 0 4.703 3.416h15.701c4.79 0 6.781 6.129 2.907 8.944l-12.703 9.23a4.94 4.94 0 0 0-1.797 5.527l4.852 14.933c1.48 4.556-3.733 8.344-7.608 5.528l-12.703-9.229a4.94 4.94 0 0 0-5.812 0l-12.703 9.23c-3.875 2.815-9.089-.973-7.609-5.528l4.853-14.934a4.94 4.94 0 0 0-1.797-5.528L2.636 31.63c-3.874-2.815-1.883-8.944 2.907-8.944h15.701c2.142 0 4.04-1.38 4.703-3.416z"
        ></path>
        <path
          fill="url(#paint2_linear_333_8310)"
          fillOpacity="0.76"
          d="M30.798 4.335c1.48-4.555 7.924-4.555 9.404 0l4.852 14.934a4.94 4.94 0 0 0 4.703 3.416h15.701c4.79 0 6.781 6.129 2.907 8.944l-12.703 9.23a4.94 4.94 0 0 0-1.797 5.527l4.852 14.933c1.48 4.556-3.733 8.344-7.608 5.528l-12.703-9.229a4.94 4.94 0 0 0-5.812 0l-12.703 9.23c-3.875 2.815-9.089-.973-7.609-5.528l4.853-14.934a4.94 4.94 0 0 0-1.797-5.528L2.636 31.63c-3.874-2.815-1.883-8.944 2.907-8.944h15.701c2.142 0 4.04-1.38 4.703-3.416z"
          style={{ mixBlendMode: "overlay" }}
        ></path>
      </>}
      {!filled &&
      <path
        stroke="#fff"
        strokeWidth="4"
        d="M32.7 4.953c.881-2.712 4.719-2.712 5.6 0l1.902-.618-1.902.618 4.852 14.934a6.94 6.94 0 0 0 6.605 4.798h15.701c2.853 0 4.038 3.65 1.731 5.326l-12.703 9.23a6.94 6.94 0 0 0-2.523 7.763l4.852 14.934c.882 2.712-2.223 4.968-4.53 3.291L39.582 56a6.94 6.94 0 0 0-8.164 0l-12.703 9.23c-2.307 1.676-5.412-.58-4.53-3.292l4.852-14.934a6.94 6.94 0 0 0-2.523-7.764L3.811 30.011c-2.307-1.676-1.122-5.326 1.73-5.326h15.702a6.94 6.94 0 0 0 6.605-4.798z"></path>}
      <defs>
        <linearGradient
          id="paint0_linear_333_8310"
          x1="35.5"
          x2="35.5"
          y1="-10.137"
          y2="84.863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7FFEA"></stop>
          <stop offset="1" stopColor="#24E5FF"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_333_8310"
          x1="35.5"
          x2="35.5"
          y1="-10.137"
          y2="84.863"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.389" stopColor="#FFD602"></stop>
          <stop offset="1" stopColor="#FF9D39"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_333_8310"
          x1="35.5"
          x2="35.5"
          y1="-10.137"
          y2="84.863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A0FFC1"></stop>
          <stop offset="0.535" stopColor="#FF5050" stopOpacity="0.465"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export const Stars = ({value=0, onClick}) => {
  const [fill, setFill] = useState(value);

  return (
    <div className={clsx(classes.stars, !onClick && classes.stars_disabled)} onMouseLeave={() => setFill(0)}>
      <Star filled={fill > 0} onHover={() => setFill(1)} onClick={() => onClick(1)}/>
      <Star filled={fill > 1} onHover={() => setFill(2)} onClick={() => onClick(2)}/>
      <Star filled={fill > 2} onHover={() => setFill(3)} onClick={() => onClick(3)}/>
      <Star filled={fill > 3} onHover={() => setFill(4)} onClick={() => onClick(4)}/>
      <Star filled={fill > 4} onHover={() => setFill(5)} onClick={() => onClick(5)}/>

    </div>
  )
}


const reasons = [
  "NotMatchingRequest",
  "PoorQuality",
  "Inappropriate"
]

/**
 *
 * @param {Object} props
 * @param {import('../ChallengePhoto').UserEvaluationInfo} props.myValidation
 * @returns
 */
const Notation = () => {

  const [step, setStep] = useState(STEP_CHOICE);
  const [reason, setReason] = useState(null);

  function handleChooseStars(nbStars){
    ChallengePhoto.evaluatePhoto(true, {Stars: nbStars})
  }

  function handleChooseReason(r) {
    setReason(r)
    setStep(STEP_REASON_CONFIRM)
  }

  function handleSubmitReason() {
    ChallengePhoto.evaluatePhoto(false, {Reason: reason})
  }


  return (
    <div className={classes.Notation}>
      {step === STEP_CHOICE &&
      <>
        {Texts.get("challengephoto-notation-title")}

        <div className={classes.buttons}>

          <div className={classes.button} onClick={() => setStep(STEP_REASON)}>
            <img src={btn_non} /> {Texts.get("challengephoto-notation-no")}
          </div>
          <div className={classes.button} onClick={() => setStep(STEP_STARS)}>
            <img src={btn_oui} /> {Texts.get("challengephoto-notation-yes")}
          </div>

        </div>
      </>}

      {step === STEP_STARS &&
      <>
        <Button className={classes.back_button} icon={<FaChevronLeft />} onClick={() => setStep(STEP_CHOICE)} />
        <div dangerouslySetInnerHTML={{__html: Texts.get("challengephoto-notation-yes-title")}} />
        <Stars onClick={handleChooseStars}/>
      </>}
      {step === STEP_REASON &&
      <>
        <Button className={classes.back_button} icon={<FaChevronLeft />} onClick={() => setStep(STEP_CHOICE)} />
        <div dangerouslySetInnerHTML={{__html: Texts.get("challengephoto-notation-no-title")}} />
        {reasons.map((r, index) => {
          return  (
          <div className={classes.reason} key={index} onClick={() => handleChooseReason(r)}>
            {Texts.get(r)}
          </div>)
        })}

      </>}

      {step === STEP_REASON_CONFIRM &&
      <>
        <Button className={classes.back_button} icon={<FaChevronLeft />} onClick={() => setStep(STEP_REASON)} />

        <div dangerouslySetInnerHTML={{__html: Texts.get("challengephoto-notation-no-title")}} />

        <div className={clsx(classes.reason, classes.highlighted)}>
          {Texts.get(reason)}
        </div>
        <p dangerouslySetInnerHTML={{__html: Texts.get("challengephoto-notation-no-warning")}} />
        <Button onClick={handleSubmitReason}>{Texts.get("challengephoto-notation-no-confirm")}</Button>
      </>}
    </div>
  );
};

export default Notation;
