import AppState from "@/js/AppState"
import { makeAutoObservable, toJS } from "mobx"

export const NOT_OPENED_YET = 0
export const OPENED = 1
export const CLOSED = 2

/**
 * @typedef UserEvaluationInfo
 * @type {{Evaluation: boolean, Options: {Stars: number, Reason: string}}}

/**
 * @typedef EvaluationInfo
 * @type {Object.<string, Object.<string, UserEvaluationInfo>>}


/**
 * @typedef GroupData
 * @type {object}
 * @property {string} _id
 * @property {string} TeamID
 * @property {string} PhotoMini
 * @property {string} Photo
 * @property {number} PositiveEvaluationCount
 * @property {number} NegativeEvaluationCount
 * @property {EvaluationInfo} EvaluationInfo
 * @property {boolean} [Validated]
 * @property {number} [StarsAverage]
 * @property {object} [ReasonsCount]
 * @property {number} [TeamProgression]
 * @property {boolean} [TeamProgressionCompleted]
 * @property {boolean} notified
 */

class ChallengePhoto {
  activity = null
  userId = null
  teamId = null
  netId = null

  ScheduledClosingTime = null
  CloseTime = null
  loading = true

  consigne = null

  /** @type {GroupData} */
  myData = null

  /** @type {Array<GroupData>} */
  thumbnails = []

  selectedThumbId = null

  allPhotosEvaluated = false
  totalThumbnails = 0
  selectedPicture = null

  constructor() {
    this.handleActivityMessage = this.handleActivityMessage.bind(this)

    window.addEventListener("meetingMessage", event => {
      console.log("meetingMessage", event)
    })

    makeAutoObservable(this)
  }
  init(userId, teamId, activityNetId, count = 0) {
    console.log("ChallengePhoto => init", activityNetId)
    this.userId = userId
    this.teamId = teamId.replace("magasins.", "")
    this.loading = true

    this.consigne = null

    this.myData = null
    this.thumbnails = []
    this.selectedThumbId = null

    this.totalThumbnails = 0

    this.activity = AppState.currentMeeting.findActivity(activity => activity.NetID === activityNetId)

    if (!this.activity) {
      if (count > 5) {
        alert("L'activité RekupoPhoto n'a pas été trouvée !!")
        return
      }

      // HACK si on trouve pas l'activity, on reload les activities, on rappelle init, 5x max
      AppState.currentMeeting.getActivities().then(() => this.init(userId, teamId, activityNetId, count + 1))

      return
    }
    this.netId = this.activity.NetID

    this.consigne = this.activity.InitData.Consigne

    this.fetchMyGroupData() // 1 on chope notre photo
      .then(() => this.fetchMyEvaluatedGroupData()) // 2 on chope les photos qu'on a déja évaluées
      .then(() => this.fetchNextDataToValidate()) // 3 on chope une image non évaluée
      .then(() => (this.loading = false))

    window.addEventListener("activityMessage", this.handleActivityMessage)
  }

  get allThumbnailsEvaluated() {
    // trouve un thumbnail qu'on n'a pas encore évalué
    let thereIsAThumbToEvaluate = this.thumbnails.find(thumb => {
      let myEval = this.getMyEvaluation(thumb)
      return !myEval
    })

    return !thereIsAThumbToEvaluate
  }

  fetchGroupData(teamId) {
    return AppState.currentMeeting.customActivityCommand("GetGroupData", this.netId, true, {
      TeamId: teamId
    })
  }

  fetchMyGroupData() {
    return this.fetchGroupData(this.teamId).then(data => {
      if (data) {
        this.myData = data
        this.selectedThumbId = data._id
      }
    })
  }

  mergeThumbnails(groupData) {
    console.log("mergeThumbnails", groupData)
    let existant = this.thumbnails.find(t => t._id !== groupData._id) // on récupère l'éventuelle existante
    console.log("existant", existant)

    if (existant) {
      existant = { ...existant, ...groupData }
      // TESTER SI CA SUFFIT POUR TRIGGER LE REFRESH ?
    } else {
      // NOUVELLE IMAGE, ON L'INSERE AU DEBUT
      this.thumbnails = [groupData, ...this.thumbnails]
      console.log("this.thumbnails", toJS(this.thumbnails))
    }
  }

  fetchMyEvaluatedGroupData() {
    return AppState.currentMeeting
      .customActivityCommand("GetEvaluatedGroupData", this.netId, true, {
        TeamId: this.teamId
      })
      .then(data => {
        if (data) {
          this.thumbnails = data
        }
      })
  }

  fetchNextDataToValidate() {
    if (!this.myData) return Promise.resolve()
    if (this.state === CLOSED) return Promise.resolve()

    // on a encore un truc à éval en local, donc on fait rien
    // BUG ceci empêche le fetch si on a tout rempli et qqn poste une nouvelle photo
    if (!this.allThumbnailsEvaluated) return Promise.resolve()

    return AppState.currentMeeting
      .customActivityCommand("GetGroupDataToValidate", this.netId, true, {
        TeamId: this.teamId
      })
      .then(data => {
        if (data) {
          this.thumbnails = [data, ...this.thumbnails]
          this.allPhotosEvaluated = false
        } else {
          this.allPhotosEvaluated = true
        }
      })
  }

  /**
   * Appelé au clic sur un thumbnail dans le footer
   * @param {GroupData} thumb
   */
  selectThumbnail(thumb) {
    this.selectedThumbId = thumb._id
    thumb.notified = false
  }

  get selectedThumb() {
    // ICI renvoyer notre photo si c'est bien elle ????
    if (this.selectedThumbId === this.myData?._id) return this.myData

    return this.thumbnails.find(thumb => thumb._id === this.selectedThumbId)
  }

  get state() {
    return this.activity.Opened ? OPENED : this.activity.CloseTime === 0 ? NOT_OPENED_YET : CLOSED
  }

  /**
   *
   * @param {GroupData} thumb
   * @returns {UserEvaluationInfo}
   */
  getMyEvaluation(thumb) {
    return thumb.EvaluationInfo?.[this.teamId]?.[this.userId]
  }

  uploadPhoto(photo, thumbnail) {
    return AppState.currentMeeting.customActivityCommand("SavePhoto", this.netId, true, {
      TeamID: this.teamId,
      Photo: photo,
      PhotoMini: thumbnail
    })
  }

  evaluatePhoto(validated, options) {
    const params = {
      AssessorTeamId: this.teamId,
      EvaluatedThumbId: this.selectedThumbId,
      Validate: validated,
      Options: options
    }
    return AppState.currentMeeting.customActivityCommand("EvaluatePhoto", this.netId, true, params)
  }

  cleanup() {
    console.log("ChallengePhoto => cleanup")

    window.removeEventListener("activityMessage", this.handleActivityMessage)
  }

  handleActivityMessage(event) {
    let msg = event?.detail?.message

    if (msg.Command) {
      console.log("ChallengePhoto event", event)
    }

    let cmd = msg?.Command

    if (cmd === "PhotoSaved") {
      // ce message ne renvoie que TeamId
      let teamId = msg?.Value?.TeamID

      if (teamId === this.teamId) {
        // c'est le message de post de notre photo, on la récup, puis on chope une thumb
        this.fetchMyGroupData().then(() => this.fetchNextDataToValidate())
      } else {
        // ici si qqn uploade une photo, je lance une récupération (sera fait uniquement si nécessaire)
        this.fetchNextDataToValidate()
      }
    }

    if (cmd === "PhotoEvaluated") {
      if (!msg.Value) return

      let {
        _id,
        TeamID,
        PositiveEvaluationCount,
        NegativeEvaluationCount,
        EvaluationInfo,
        Validated,
        StarsAverage,
        ReasonsCount,
        TeamProgression,
        TeamProgressionCompleted
      } = msg.Value

      let target = _id === this.myData?._id ? this.myData : this.thumbnails.find(t => t._id === _id)

      if (target) {
        // on notif la thumb seulement au moment ou elle passe en "Validée", et seulement si on n'est pas déja en train de la regarder
        if (target !== this.selectedThumb && target.Validated === undefined && Validated !== undefined)
          target.notified = true

        target.PositiveEvaluationCount = PositiveEvaluationCount
        target.NegativeEvaluationCount = NegativeEvaluationCount
        target.EvaluationInfo = EvaluationInfo
        target.Validated = Validated
        target.StarsAverage = StarsAverage
        target.ReasonsCount = ReasonsCount

        // NOTE pas sur de garder ça ici, ca sse met à jour via le message TeamProgression
        target.TeamProgression = TeamProgression
        target.TeamProgressionCompleted = TeamProgressionCompleted
      }

      // on ne peut pas vraiment le faire suite à la requête "EvaluatePhoto" car
      // l'état du thumb est tjs en cours d'éval...
      this.fetchNextDataToValidate()
    }

    if (cmd === "TeamProgression") {
      // ici une équipe a reçu une progression, on l'enregistre, surtout si c'est nous
      if (!msg.Value) return

      let { TeamID, TeamProgression, TeamProgressionCompleted } = msg.Value

      // let target = _id === this.myData?._id ? this.myData : this.thumbnails.find(t => t._id === _id)
      let target = TeamID === this.teamId ? this.myData : this.thumbnails.find(t => t.TeamID === TeamID)

      if (target) {
        target.TeamProgression = TeamProgression
        target.TeamProgressionCompleted = TeamProgressionCompleted
      }
    }
  }
}

export default new ChallengePhoto()
