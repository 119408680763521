import { computed, makeAutoObservable, makeObservable, observable } from "mobx"
import AppState from "./AppState"
import Notifications from "react-sdk/Notifications/Notifications"


export const COLLECTE_STATUS = {
  VALIDEE: "VALIDEE",
  ACONFIRMER: "ACONFIRMER",
  CONFIRMEE: "CONFIRMEE",
  DEPASSEE: "DEPASSEE",
  ENREGISTREE: "ENREGISTREE",
  PLANIFIEE: "PLANIFIEE",
  REFUSEE: "REFUSEE",
  SUPPRIMEE: "SUPPRIMEE",
  ARCHIVE_EN_COURS: "ARCHIVE_EN_COURS",
  ARCHIVE: "ARCHIVE"
}



export class Collecte {


  id
  Estimation
  code_PDC_PO // ex: FDTEST
  Counter // ?
  anomaly_occurred
  anomaly_type
  conformance_status
  date_confirmation_DE
  date_confirmation_DE_ms
  nom_PDC_PO
  numero_DE
  statut_DE
  total_weight
  Score
  ScoreCoeff


  constructor(json) {
    Object.assign(this, json)
    makeObservable(this, {
      id : observable,
      Estimation : observable,
      code_PDC_PO : observable,
      Counter : observable,
      anomaly_occurred : observable,
      anomaly_type : observable,
      conformance_status : observable,
      date_confirmation_DE : observable,
      date_confirmation_DE_ms : observable,
      nom_PDC_PO : observable,
      numero_DE : observable,
      statut_DE : observable,
      total_weight : observable,
      Score : observable,
      ScoreCoeff : observable,

      EstimationKG: computed,
      date_confirmation_DE_toLocale: computed
    })
  }

  get date_confirmation_DE_toLocale() {
    const d = new Date(this.date_confirmation_DE)
    const day = d.toLocaleDateString()
    const time = d.toLocaleTimeString()
    return {day, time}
  }

  get EstimationKG() {
    if(this.Estimation) {
      return Math.round(this.Estimation * 1000)
    }
    return undefined
  }

  setEstimation(value) {

    let valueTonnes = parseInt(value) / 1000

    return AppState.currentMeeting.customActivityCommand("MongoSetEstimation", AppState.collecteActivityId, true, {
      RekupoId: this.id,
      Estimation: valueTonnes
    })
    .then(result => {
      if(result) {
        this.Estimation = valueTonnes
      }
    })


  }
}


class Collectes {

  /** @type {Array<Collecte>} */
  all = []

  constructor() {
    makeAutoObservable(this)
  }

  fetch() {
    /* {"Command" : "MongoGetRecentData",
      "StatusToInclude" : [...],
      "StatusToExclude" : [...],
      Offset: 0,
      Size: 5
    }*/

    this.all = []

    let groupID = AppState.team.groupId.replace("magasins.", "").toUpperCase()

    return AppState.currentMeeting.customActivityCommand("MongoGetRecentData", AppState.collecteActivityId, true, {
      StatusToInclude: [COLLECTE_STATUS.VALIDEE, COLLECTE_STATUS.CONFIRMEE],
      // StatusToExclude: [],
      Offset: 0,
      // Size: 50,
      Group : groupID // ex: FB0684
    })
    .then(result => {
      if(result) this.all = result.map(d => new Collecte(d))
    })
    .catch(err => {
      Notifications.error(err)
    })
  }

  getSingleCollecte(collecte_id) {


    return AppState.currentMeeting.customActivityCommand("MongoGetData", AppState.collecteActivityId, true, {
      RekupoId: collecte_id
    })
    .then(result => {
      console.log("MongoGetData result", result)

      return new Collecte(result)
    })
    .catch(err => {
      Notifications.error(err)
    })

  }


  /** @returns {Collecte} */
  get(id) {
    return this.all.find(c => c.id === id)
  }

}


export default new Collectes()